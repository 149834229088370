import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import ParcelsLogo from '../../../../../shared/assets/images/ParcelsLogo/parcels-logo.png';
import { handleReturnMyAccounts } from '../../../../../shared/utils/helpers/purefunctions';
import StickyHeader from '../../../../../shared/components/StickyHeader';
import { MODULE_PATH } from '../../../../../shared/constants/Module';

const AboutUs = () => {
  const navigate = useNavigate();
  const { appInfo } = useSelector(state => state.appAccessReducer);

  const prevNavigate = {
    label: 'About Us',
    imgPath: '',
    path: MODULE_PATH.MY_ACCOUNTS.MAIN,
  };

  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <StickyHeader title="About Us" onPrevNavigate={prevNavigate} />
        <div className="body-content">
          <div className="parcels-logo-container">
            <img src={ParcelsLogo} alt="Parcels logo" className="parcels-logo" />
            <div className="version">
              {appInfo?.version || 'v4.0.0 (1.0.144) Build; Build 1.0.144'}
            </div>
            <div className="powered-by">
              Powered by <i>Last Mile, Inc.</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
