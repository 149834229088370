import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setPrevRoute } from '../redux/home/actions';
import { setVehicleType, setCourier } from '../redux/onDemand/actions';
import arrowRight from '../assets/images/OndemandDelivery/arrow_right.svg';

import kgImage from '../assets/images/OndemandDelivery/kg.svg';
import { VEHICLE_TYPES } from '../utils/enums/DeliveryConstants';
import StickyHeader from './StickyHeader';
import { ICON, ON_DEMAND_ICON } from '../utils/enums/AppIcons';
import { MODULE_PATH } from '../constants/Module';
import { COURIER_VEHICLE_TYPES } from '../utils/enums/Providers';
import { formatUnderscoredText } from '../utils/helpers/purefunctions';

const VehicleTypeList = ({ setVehicleType, setOndemandCourier, setPrevRoute }) => {
  let navigate = useNavigate();
  const location = useLocation();
  const onDemandProps = useSelector(state => state.onDemandReducer);
  const { vehicleType: selectedVehicle } = onDemandProps;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const navigatePrevPath =
    location?.state?.view === 'Home' ? MODULE_PATH.HOME : MODULE_PATH.ON_DEMAND.MAIN;

  const prevNavigate = {
    label: 'Choose Vehicle Type',
    imgPath: ICON.ON_DEMAND,
    path: navigatePrevPath,
  };

  const VehicleIcon = ({ value }) => {
    let vehicleIcon = 'MOTORCYCLE';
    vehicleIcon = COURIER_VEHICLE_TYPES[value.toLowerCase()];
    return <img src={ON_DEMAND_ICON[vehicleIcon]} alt={value} />;
  };

  const handleSelectVehicleType = data => {
    const isVehicleChange = data.vehicleType !== selectedVehicle.vehicleType;
    if (isVehicleChange) {
      setVehicleType(data);
      setOndemandCourier({});
    } else {
      setVehicleType(data);
    }
    setPrevRoute('vehicleType');
    navigate(navigatePrevPath, { state: { ...location?.state, isVehicleChange: isVehicleChange } });
  };

  return (
    <div className="vehicle__container">
      <StickyHeader title="Choose Vehicle Type" onPrevNavigate={prevNavigate} />
      <span className="list__header v4 hdln-block">Available Vehicles</span>
      {VEHICLE_TYPES.map((data, index) => (
        <div className="vehicle__list-v4" key={index}>
          <div className="info" onClick={() => handleSelectVehicleType(data)}>
            <span className="vehicle-icon-container">
              <VehicleIcon value={data.value} />
            </span>
            <div className="vehicleType-info">
              <div className="header lbl-base">
                {data.value === 'MPV' ? data.value : formatUnderscoredText(data.value)}
              </div>
              <div className="content cntnt-small-caption">{data.content}</div>
              <div className="details cntnt-small-caption">
                <span>
                  <img src={kgImage} alt="kg" />
                </span>
                <span>{data.description.dimensionText}</span>
                <span>•</span>

                <span>{data.description.weightText}</span>
              </div>
            </div>
            <span className="arrow-right">
              <img src={arrowRight} alt="arrow-right" />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setVehicleType,
      setOndemandCourier: setCourier,
      setPrevRoute,
    },
    dispatch
  );

export default connect(null, matchDispatchToProps)(VehicleTypeList);
