import React from 'react';
import { Drawer, Space, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SubmitButton from '../../../../shared/components/SubmitButton';

import { ICON } from '../../../../shared/utils/enums/AppIcons';

const BookAnyHelp = ({ show, onSetShow }) => {
  return (
    <Drawer
      title="Book Any Courier"
      placement="bottom"
      height="r50px"
      className="book__any__drawer"
      onClose={() => onSetShow()}
      open={show}
      closable={false}
      extra={
        <Space>
          <Button className="close-btn" onClick={() => onSetShow()}>
            <FontAwesomeIcon icon={solid('x')} />
          </Button>
        </Space>
      }
    >
      <div className="book__any__drawer__container">
        <div className="book__any__instruction">
          <span className="book__any__icon">
            <img src={ICON.HELP_GRAY} />
          </span>
          <span className="book__any__content">
            <span className="book__any__title lbl-base">Quick Tip</span>
            <span className="book__any__info cntnt-caption">
              This option ensures that a courier will be assigned to your order, and lessens the
              risk of order cancellation due to booking errors or courier unavailability.
            </span>
          </span>
        </div>
        <div className="book__any__instruction">
          <span className="book__any__icon">
            <img src={ICON.PAYMENT_ICON_GRAY} />
          </span>
          <span className="book__any__content">
            <span className="book__any__title lbl-base">Payment</span>
            <span className="book__any__info cntnt-caption">
              Highest fee may differ from actual courier fee. Extra fee can be applied as cashback
              for your next delivery with us.
            </span>
          </span>
        </div>
        <div className="book__any__instruction">
          <span className="book__any__icon">
            <img src={ICON.CASHBACK_ICON_GRAY} />
          </span>
          <span className="book__any__content">
            <span className="book__any__title lbl-base">Cashback</span>
            <span className="book__any__info cntnt-caption">
              Get a chance to earn cashback reward in your next delivery with this option!
            </span>
          </span>
        </div>
        <SubmitButton isProceedValue={true} name="Got it" onClick={() => onSetShow()} />
      </div>
    </Drawer>
  );
};

export default BookAnyHelp;
