import React from 'react';

function DeliveryType({
  type,
  className,
  deliveryIcon,
  refName,
  deliveryType,
  deliverySubType,
  transFormDeliveryType,
  handleSetDeliveryType,
}) {
  return (
    <div className={`${className} ${deliveryType}`} onClick={() => handleSetDeliveryType(refName)}>
      <img src={deliveryIcon} alt={`${transFormDeliveryType} logo`} />
      <div className="type">
        <span className='lbl-base'>{type}</span>
        <span className='cntnt-caption-bold'>{deliverySubType || 'Delivery'}</span>
      </div>
    </div>
  );
}

export default DeliveryType;
