import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { message, Modal } from 'antd';
import DPHDAO from '../../../shared/utils/dao/DPH';
import { TRADE_PAY } from '../../../shared/utils/enums/GCashResponse';
import PaymentDAO from '../../../shared/utils/dao/PaymentDAO';
import {
  setPaymentRequestId,
  setCourier,
  setOndemandCashBackAmount,
  setCurrentCourierRates,
} from '../../../shared/redux/onDemand/actions';
import {
  setStandardPaymentRequestId,
  setStandardCashBackAmount,
} from '../../../shared/redux/standard/actions';
import { setLoader } from '../../../shared/redux/home/actions';
import { formatBills } from '../../../shared/utils/helpers/purefunctions';

import {
  CONVENIENCE_FEE,
  COURIERS,
  PERCENT_BASE_FEE,
} from '../../../shared/utils/enums/DeliveryConstants';

import { onDemandPayload, scheduledPayload } from '../../../shared/utils/helpers/Payload';
import PaymentSummaryV2 from '../../../shared/components/v2/PaymentSummary';
import { BOOK_ANY, DELIVERY_TYPE_VIEW } from '../../../shared/utils/enums/AppConstants';
import { MODULE_PATH } from '../../../shared/constants/Module';

const confirm = Modal.confirm;

const PaymentSummary = ({
  onDemandcourier,
  standardcourier,
  vehicleType,
  setLoader,
  onDemandProps,
  standardProps,
  userInfo,
  onDemandPaymentRequestId,
  standardPaymentRequestId,
  setCourier,
  setOndemandCashBackAmount,
  setStandardCashBackAmount,
  setCurrentCourierRates,
}) => {
  let navigate = useNavigate();

  const [requestId, setRequestId] = useState('');
  const [cashBack, setCashBack] = useState(0);
  const [switchCount, setSwitchCount] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [voucherAmount, setVoucherAmount] = useState(0);
  const [intervalFunction, setIntervalFunction] = useState(null);
  const [useCashBack, setUseCashBack] = useState(false);
  const [isPaymentCliked, setIsPaymentClicked] = useState(false);

  const { formTab } = useSelector(state => state.homeReducer);

  const isStandard = formTab === 'standard-view';

  const courier = isStandard ? standardcourier : onDemandcourier;
  const isBookAny = courier.courier === BOOK_ANY;

  const cashBackAmount = useCashBack && cashBack;
  const convenienceFee = CONVENIENCE_FEE.REGULAR;

  const standardShippingFee = standardcourier.rate;

  const shippingFee = isStandard ? Number(standardShippingFee) : onDemandcourier.rate;
  const refNo = isStandard ? standardProps?.orderId : onDemandProps?.orderId;
  const { cashbackDeduction } = onDemandProps.cashbackPaymentDetails;

  const discountFees = Number(voucherAmount) + Number(cashBackAmount);
  const rate = formatBills(shippingFee - discountFees);

  const paymentProcessPath =
    formTab === DELIVERY_TYPE_VIEW.onDemand
      ? MODULE_PATH.PAYMENT.ON_DEMAND.PAYMENT_PROCESS
      : MODULE_PATH.PAYMENT.STANDARD.PAYMENT_PROCESS;

  useEffect(() => {
    setLoader(false);
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(intervalFunction);
    };
  }, [intervalFunction]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY;
  //     const scrollThreshold = 100;

  //     if (prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > scrollThreshold) {
  //       window.scrollTo(0, 0);
  //     }

  //     setPrevScrollPos(currentScrollPos);
  //   };

  //   const fetchCashBack = async () => {
  //     const payload = {
  //       userId: userInfo.currentUser.id || '',
  //       paymentAmount: courier.rate,
  //       refNo: refNo,
  //     };

  //     // const paymentDao = new PaymentDAO();
  //     // const amountAfterCashBack = await paymentDao.getCashBack(payload);

  //     // if (amountAfterCashBack?.result) {
  //     //   const newCashback = courier.rate - amountAfterCashBack.result.paymentAmount;
  //     //   setCashBack(newCashback);
  //     // }
  //   };

  //   if (onDemandPaymentRequestId) {
  //     setRequestId(onDemandPaymentRequestId);
  //   }
  //   if (standardPaymentRequestId) {
  //     setRequestId(standardPaymentRequestId);
  //   }

  //   if (useCashBack && Number(switchCount) === 1) {
  //     fetchCashBack();
  //     window.addEventListener('scroll', handleScroll);
  //   }

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [userInfo, useCashBack, prevScrollPos]);

  const failedGcashPaymentInquiry = (userId, paymentRequestId, res) => {
    const PaymentDao = new PaymentDAO();
    PaymentDao.gcashPaymentInquiry({
      userId,
      paymentRequestId,
    });
    // message.info(TRADE_PAY[res?.resultCode] || `${JSON.stringify(res?.resultCode) || ''}`, 3.0);
  };

  const payGCash = async amount => {
    setLoader(false);
    await proceedPayWithGCash(amount);
  };

  const payWithGCash = async amount => {
    setLoader(true);

    if (isStandard) {
      const scheduledQoutePayload = scheduledPayload(standardProps, userInfo);
      const standard = await DPHDAO.getAvailableScheduledPartner(scheduledQoutePayload);

      if (standard) {
        const filteredStandardCourier = standard.filterCouriers.filter(
          data => data.id === standardcourier.courier
        );

        const [productInfo] = filteredStandardCourier
          .map(data =>
            data.products.filter(
              productDetails => productDetails.size === standardProps.product.value.charAt(0)
            )
          )
          .flat();

        if (filteredStandardCourier.length) {
          if (productInfo.price !== standardcourier.rate) {
            const newRate = productInfo.price;

            setLoader(false);

            confirm({
              className: 'discard-order',
              okText: 'Yes',
              cancelText: 'No',
              content: `Courier rate has changed, from P ${formatBills(
                standardcourier.rate
              )} to P ${formatBills(newRate)}. Would you like to proceed?`,
              onOk: async () => {
                setCourier({ ...standardcourier, rate: newRate });
              },
              onCancel: () => {},
            });
          } else {
            await payGCash(amount);
          }
        } else {
          await payGCash(amount);
        }
      } else {
        setLoader(false);
        return message.error('Please retry payment.', 3.0);
      }
    } else {
      let onDemand;
      let currentCourierRates = {};
      let highestRatedCourier = {};

      const onDemandQoutePayload = onDemandPayload(
        onDemandProps,
        [onDemandcourier.courier.toUpperCase()],
        userInfo
      );

      if (isBookAny) {
        onDemand = await DPHDAO.getOnDemandQuotation(
          onDemandPayload(onDemandProps, COURIERS, userInfo)
        );

        currentCourierRates = onDemand.reduce((rates, { courier, rate }) => {
          rates[courier] = rate;
          return rates;
        }, {});

        highestRatedCourier = onDemand.reduce(
          (maxRate, obj) => (obj.rate > maxRate.rate ? obj : maxRate),
          onDemand[0]
        );
      } else {
        onDemand = await DPHDAO.getOnDemandQuotation(onDemandQoutePayload);
      }

      if (onDemand) {
        const currentOndemandCourier = isBookAny
          ? highestRatedCourier.courier
          : onDemandcourier.courier;

        const getQuote = onDemand.filter(quote => quote.courier === currentOndemandCourier);

        if (getQuote.length) {
          if (getQuote[0].rate !== onDemandcourier.rate) {
            const newRate = Number(getQuote[0].rate || 0);
            setLoader(false);
            confirm({
              className: 'discard-order',
              okText: 'Yes',
              cancelText: 'No',
              content: `${
                isBookAny ? 'Book Any' : 'Courier'
              } rate has changed, from P ${formatBills(onDemandcourier.rate)} to P ${formatBills(
                newRate
              )}. Would you like to proceed?`,
              onOk: async () => {
                setCourier({ ...onDemandcourier, rate: newRate });
                setCurrentCourierRates(currentCourierRates);
              },
              onCancel: () => {},
            });
          } else {
            await payGCash(amount);
          }
        } else {
          await payGCash(amount);
        }
      } else {
        setLoader(false);
        return message.error('Please retry payment.', 3.0);
      }
    }
  };

  function paymentInquiryInterval(userId, createdPaymentRequestId) {
    const setIntervalId = setInterval(() => {
      failedGcashPaymentInquiry(userId, createdPaymentRequestId);
    }, 60 * 1000);
    setIntervalFunction(setIntervalId);
  }

  function myTradePay(
    paymentUrl,
    createdPaymentRequestId,
    createdPaymentId,
    paymentAmount,
    paymentAPI
  ) {
    my.tradePay({
      paymentUrl,
      success: function (res) {
        if (['9000', '8000', '4000'].includes(String(res?.resultCode))) {
          return navigate(paymentProcessPath, {
            state: {
              gcash: {
                paymentRequestId: createdPaymentRequestId,
                paymentId: createdPaymentId,
                paymentAmount,
              },
              refNo: paymentAPI.refNo,
              useCashBack: useCashBack,
            },
          });
        } else {
          setLoader(false);
          setIsPaymentClicked(false);
          failedGcashPaymentInquiry(userInfo.currentUser.id, createdPaymentRequestId);
        }
        setLoader(false);
        setIsPaymentClicked(false);
      },
      fail: function (res) {
        setLoader(false);
        setIsPaymentClicked(false);
        failedGcashPaymentInquiry(userInfo.currentUser.id, createdPaymentRequestId);
        message.error(
          TRADE_PAY[res?.resultCode] || `Failed: Processing Error ${res?.resultCode || ''}`,
          3.0
        );
      },
    });
  }

  const proceedPayWithGCash = async amount => {
    let paymentAPI = {};
    let userPayment;

    const userId = userInfo.currentUser.id;

    if (useCashBack) {
      const { cashbackPaymentDetails } = onDemandProps;
      userPayment = parseFloat(cashbackPaymentDetails.paymentAmount).toFixed(2);
    } else {
      userPayment = parseFloat(parseFloat(rate) + parseFloat(amount)).toFixed(2);
    }

    const paymentAmount = {
      currency: 'PHP',
      value: String(userPayment).split('.').join('') || '000',
      voucherAmount,
    };

    if (isStandard) {
      paymentAPI = {
        userId,
        paymentAmount,
        paymentRequestId: requestId || standardPaymentRequestId,
        refNo: standardProps?.orderId,
        paymentOrderTitle: standardProps?.itemType || standardProps?.itemDescription,
      };
    } else {
      paymentAPI = {
        userId,
        paymentAmount,
        paymentRequestId: requestId || onDemandPaymentRequestId,
        refNo: onDemandProps?.orderId,
        paymentOrderTitle: onDemandProps?.itemType || onDemandProps?.itemDescription,
      };
    }

    /* call payments/pay via parcelsIO first to get the paymentRequestId and payment status */
    const paymentDao = new PaymentDAO();
    const paidBooking = await paymentDao.gcashPayment(paymentAPI);

    if (paidBooking.success) {
      /* create booking order first so once notify url response, booking order exist */
      const createdPaymentRequestId = paidBooking.result.paymentRequestId;
      const createdPaymentId = paidBooking.result.paymentId;
      const paymentUrl = paidBooking.result.actionForm.redirectionUrl;

      paymentInquiryInterval(userId, createdPaymentRequestId);
      myTradePay(paymentUrl, createdPaymentRequestId, createdPaymentId, paymentAmount, paymentAPI);
    } else {
      // message.info(`Failed: ${paidBooking.message}`, 3.0);
    }
  };

  const handleCashBackSwitch = boolean => {
    setUseCashBack(boolean);
    setSwitchCount(switchCount + 1);
    if (boolean) {
      isStandard ? setStandardCashBackAmount(cashBack) : setOndemandCashBackAmount(cashBack);
    } else {
      isStandard ? setStandardCashBackAmount(0) : setOndemandCashBackAmount(0);
    }
  };

  return (
    <PaymentSummaryV2
      setCashBack={setCashBack}
      setUseCashBack={setUseCashBack}
      useCashBack={useCashBack}
      cashBack={cashBack}
      handleCashBackSwitch={handleCashBackSwitch}
      setVoucherAmount={setVoucherAmount}
      voucherAmount={voucherAmount}
      cashBackAmount={cashBackAmount}
      discountFees={discountFees}
      shippingFee={shippingFee}
      rate={rate}
      convenienceFee={convenienceFee}
      // payWithGCash={() =>
      //   navigate(paymentProcessPath)
      // } /* this commented code is for creating booking in local env. */
      payWithGCash={amount => payWithGCash(amount)}
      isPaymentCliked={isPaymentCliked}
      setIsPaymentClicked={bool => setIsPaymentClicked(bool)}
    />
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLoader,
      setOnDemandPaymentRequestId: setPaymentRequestId,
      setStandardPaymentRequestId,
      setCourier,
      setOndemandCashBackAmount,
      setStandardCashBackAmount,
      setCurrentCourierRates,
    },
    dispatch
  );
}

const mapStateToProps = state => ({
  onDemandcourier: state.onDemandReducer.courier,
  standardcourier: state.standardReducer.courier,
  vehicleType: state.onDemandReducer.vehicleType,
  onDemandPaymentRequestId: state.onDemandReducer.paymentRequestId,
  standardPaymentRequestId: state.standardReducer.paymentRequestId,
  standardItemPrice: state.standardReducer.itemPrice,
  onDemandProps: state.onDemandReducer,
  standardProps: state.standardReducer,
  userInfo: state.usersReducer,
});
export default connect(mapStateToProps, matchDispatchToProps)(PaymentSummary);
