import React, { useState, useEffect, useContext } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Drawer, Space, Button, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SubmitButton from './SubmitButton';
import FormInput from './FormInput';
import { ITEM_TYPES } from '../utils/enums/AppIcons';
import { formatBills, formatText, isEmpty } from '../utils/helpers/purefunctions';
import {
  setOnDemandItemType,
  setOnDemandItemDescription,
  setItemPrice as setOnDemandItemPrice,
  setItemApproxWeight as setOnDemandItemApproxWeight,
} from '../redux/onDemand/actions';
import {
  setStandardItemType,
  setStandardItemDescription,
  setItemPrice as setStandardItemPrice,
  setItemApproxWeight as setStandardItemApproxWeight,
} from '../redux/standard/actions';
import { setPrevRoute } from '../redux/home/actions';
import { MODULE_PATH } from '../constants/Module';
import ItemDecriptionContext from '../context/ItemDescriptionContext';
import ItemRateContext from '../context/ItemRateContext';
import ItemTypeContext from '../context/ItemTypeContext';

const ItemDetailsList = ({
  show,
  view,
  formTab,
  onCloseItemDetailsDrawer,
  setOnDemandItemType,
  setStandardItemType,
  setOnDemandItemDescription,
  setStandardItemDescription,
  standardItemType,
  onDemandItemType,
  onDemandItemPrice,
  onDemandItemApproxWeight,
  standardItemApproxWeight,
  standardItemPrice,
  standardItemDescription,
  onDemandItemDescription,
  setStandardItemPrice,
  setOnDemandItemPrice,
  setOnDemandItemApproxWeight,
  setStandardItemApproxWeight,
  setPrevRoute,
}) => {
  const [inputFocus, setInputFocus] = useState(null);

  const { description, setDescription } = useContext(ItemDecriptionContext);
  const [itemPrice, setItemPrice] = useState(1);
  const [itemApproxWeight, onSetItemApproxWeight] = useState(1);
  const { type, setType } = useContext(ItemTypeContext);

  const isOndemand = formTab === 'onDemand-view';
  const propsType = isOndemand ? onDemandItemType : standardItemType

  useEffect(() => {
    if (isOndemand) {
      setOnDemandItemType(onDemandItemType);
      setOnDemandItemDescription(onDemandItemDescription);
      setOnDemandItemPrice(onDemandItemPrice);
      setOnDemandItemApproxWeight(
        typeof onDemandItemApproxWeight === 'string'
          ? parseFloat(onDemandItemApproxWeight)
          : onDemandItemApproxWeight
      );
    } else {
      setStandardItemType(standardItemType);
      setStandardItemDescription(standardItemDescription);
      setStandardItemPrice(standardItemPrice);
      setStandardItemApproxWeight(
        typeof standardItemApproxWeight === 'string'
          ? parseFloat(standardItemApproxWeight)
          : standardItemApproxWeight
      );
    }
  }, [type]);

  const handleItems = () => {
    if (isOndemand) {
      setOnDemandItemType(type);
      setOnDemandItemDescription(description);
      setOnDemandItemPrice(formatBills(itemPrice));
      setOnDemandItemApproxWeight(itemApproxWeight);
    } else {
      setStandardItemType(type);
      setStandardItemDescription(description);
      setStandardItemPrice(formatBills(itemPrice));
      setStandardItemApproxWeight(itemApproxWeight);
    }
    onCloseItemDetailsDrawer();
    setPrevRoute('itemDetails');
  };

  const ItemDisplay = () => {
    return (
      <>
        {Object.keys(ITEM_TYPES).map((data, index) => (
          <span
            className={`item ${(type || propsType) === data ? 'selected' : ''}`}
            onClick={() => setType(data)}
            key={index}
          >
            <span className='lbl-small-light'>{formatText(data)}</span>
            <img src={ITEM_TYPES[data.toUpperCase()]} alt="item" />
          </span>
        ))}
      </>
    );
  };

  const handleBlur = () => {
    if (!isNaN(itemApproxWeight)) {
      let formattedValue = parseFloat(itemApproxWeight).toFixed(2);
      onSetItemApproxWeight(formattedValue);
    } else {
      message.error('Invalid input');
    }
  };

  const handleRemoveInputFocus = () => {
    handleBlur();
    setInputFocus(null);
  };

  const handleApproxWeight = operation => {
    let weight = !isEmpty(itemApproxWeight) ? parseFloat(itemApproxWeight) : 0;
    if (operation === 'add') {
      onSetItemApproxWeight(parseFloat(weight + 1).toFixed(2));
    } else {
      weight > 1 && onSetItemApproxWeight(parseFloat(weight - 1).toFixed(2));
    }
  };

  const handleApproxWeightCard = num => {
    onSetItemApproxWeight(parseFloat(num));
  };

  const handleChangeApproxWeight = val => {
    if (!isEmpty(val)) {
      const num = String(Math.floor(val));

      const hasDecimal = String(val).includes('.');
      const [_, decimal] = String(val)?.split('.');

      if (hasDecimal) {
        if (decimal.length > 2) return;
      }

      if (num.length > 7) {
        return message.error('Field value should only contain 7 whole numbers.');
      }
      return onSetItemApproxWeight(parseFloat(val));
    }
    return onSetItemApproxWeight(parseFloat(val));
  };

  const approxItems = [1, 3, 5, 8, 10, 12, 15, 20];
  return (
    <Drawer
      title="Item Details"
      placement="bottom"
      height="370px"
      className="item-type-drawer"
      onClose={() => onCloseItemDetailsDrawer()}
      open={show}
      closable={false}
      extra={
        <Space>
          <Button className="close-btn" onClick={() => onCloseItemDetailsDrawer()}>
            <FontAwesomeIcon icon={solid('x')} />
          </Button>
        </Space>
      }
    >
      <div className="item-details-container">
        <span className="title cntnt-base-bold">
          What are you sending? <span className="asterisk">*</span>
        </span>
        <div className="item-types">
          <div className="viewport">
            <div className="horizontal">
              <ItemDisplay />
            </div>
          </div>
        </div>

        <div className="approx-weight">
          <span className="title cntnt-base-bold">
            Approx Weight <b>*</b>
          </span>
          {approxItems.map(item => {
            return (
              <span
                className={`${Number(item) === Number(itemApproxWeight) ? 'selected' : ''}`}
                onClick={() => handleApproxWeightCard(item)}
              >
                {`${item} kg`}
              </span>
            );
          })}
        </div>
        <div className="increment-approx-weight">
          <span className="increment-weight" onClick={() => handleApproxWeight('sub')}>
            -
          </span>
          <FormInput
            value={itemApproxWeight}
            onChange={value => handleChangeApproxWeight(Number(value))}
            onClick={() => handleChangeApproxWeight('')}
            onFocus={() => setInputFocus('itemApproxWeight')}
            inputFocusName={inputFocus === 'itemApproxWeight'}
            onBlur={() => handleRemoveInputFocus()}
            suffix="Kg(s)"
            suffixOn={true}
            type="NUMBER"
            className="rtl"
            step="0.01"
            pattern="\d+(\.\d{1,2})?"
            inputMode="numeric"
          />
          <span className="increment-weight" onClick={() => handleApproxWeight('add')}>
            +
          </span>
        </div>
        <SubmitButton isProceedValue={type} name="Confirm" onClick={() => handleItems()} />
      </div>
    </Drawer>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setOnDemandItemType,
      setStandardItemType,
      setOnDemandItemDescription,
      setStandardItemDescription,
      setStandardItemPrice,
      setOnDemandItemPrice,
      setOnDemandItemApproxWeight,
      setStandardItemApproxWeight,
      setPrevRoute,
    },
    dispatch
  );

const mapStateToProps = state => ({
  view: state.homeReducer.view,
  formTab: state.homeReducer.formTab,
  onDemandItemType: state.onDemandReducer.itemType,
  onDemandItemDescription: state.onDemandReducer.itemDescription,
  onDemandItemPrice: state.onDemandReducer.itemPrice,
  onDemandItemApproxWeight: state.onDemandReducer.itemApproxWeight,
  standardItemType: state.standardReducer.itemType,
  standardItemDescription: state.standardReducer.itemDescription,
  standardItemPrice: state.standardReducer.itemPrice,
  standardItemApproxWeight: state.standardReducer.itemApproxWeight,
});

export default connect(mapStateToProps, matchDispatchToProps)(ItemDetailsList);
