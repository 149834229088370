import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DeliveryType from '../../../../../shared/components/v3/DeliveryType';
import IconOnDemand from '../../../../../shared/assets/svg/home-booking/icon_On-demand.svg';
import IconStandard from '../../../../../shared/assets/svg/home-booking/icon_Standard.svg';
import startMapMarker from '../../../../../shared/assets/images/OndemandDelivery/start_point_location.svg';
import endMapMarker from '../../../../../shared/assets/images/location/map-marker-alt.png';
import LogoNinjavan from '../../../../../shared/assets/svg/home-booking/ninja_van_logo.svg';
import LogoXde from '../../../../../shared/assets/svg/home-booking/xde_logo.svg';
import LogoQuadX from '../../../../../shared/assets/svg/home-booking/quadx_logo.svg';
import LogoPandago from '../../../../../shared/assets/svg/home-booking/pandaGo_logo.svg';
import LogoLalamove from '../../../../../shared/assets/svg/home-booking/lalamove_logo.svg';
import LogoGrab from '../../../../../shared/assets/svg/home-booking/grab_logo.svg';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLocation as setOnDemandLocation,
  setPointLocation as setOnDemandPointLocation,
} from '../../../../../shared/redux/onDemand/actions';
import {
  setLocation as setStandardLocation,
  setPointLocation as setStandardPointLocation,
} from '../../../../../shared/redux/standard/actions';
import { setPrevRoute } from '../../../../../shared/redux/home/actions';
import { useNavigate } from 'react-router';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import { message } from 'antd';
import { ICON, ON_DEMAND_ICON } from '../../../../../shared/utils/enums/AppIcons';
import { MAPS_GEOCODE, MAPS_API_KEY } from '../../../../../shared/utils/enums/AppConstants';
import { Ellips } from '../../../../../shared/utils/helpers/purefunctions';

const Form = ({ deliveryType, handleSetDeliveryType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentAddress, setCurrentAddress] = useState('');

  const onDemandProps = useSelector(state => state.onDemandReducer);
  const standardProps = useSelector(state => state.standardReducer);

  const { formTab } = useSelector(state => state.homeReducer);

  const isOndemand = 'onDemand-view' === formTab;

  const deliveryProps = isOndemand ? onDemandProps : standardProps;
  const setPointLocationPath = isOndemand ? setOnDemandPointLocation : setStandardPointLocation;
  const setLocationPath = isOndemand ? setOnDemandLocation : setStandardLocation;

  const transFormDeliveryType = () => {
    if (deliveryType === 'onDemand-view') {
      return deliveryType.replace(/onDemand-view/g, 'on-demand');
    }
    return deliveryType.split('-')[0];
  };

  const {
    pickUp,
    dropOff,
    courier: selectedCourier,
    itemType,
    itemApproxWeight,
    vehicleType: selectedVehicle,
  } = deliveryProps;

  const CourierLogos = () => {
    if (!deliveryType) return;
    const courierListLogo = {
      'onDemand-view': {
        logoList: [LogoPandago, LogoLalamove, LogoGrab],
        nameList: ['pandago', 'lalamove', 'grab'],
      },
      'standard-view': {
        logoList: [LogoNinjavan, LogoQuadX, LogoXde],
        nameList: ['ninjavan', 'quadx', 'xde'],
      },
    };

    const { logoList, nameList } = courierListLogo[deliveryType];

    return (
      <div className="courier-logos">
        {logoList.map((logo, index) => {
          return <img key={logoList[index]} src={logo} alt={nameList[index]} />;
        })}
      </div>
    );
  };

  const MODULE_ADDRESS = isOndemand
    ? MODULE_PATH.ADDRESS.ON_DEMAND.SELECT_ADDRESS
    : MODULE_PATH.STANDARD.SENDER_DETAILS;
  const MODULE_COURIER = isOndemand
    ? MODULE_PATH.SHARED.ON_DEMAND.COURIER
    : MODULE_PATH.SHARED.STANDARD.COURIER;
  const MODULE_PAYMENT_SUMMARY = isOndemand
    ? MODULE_PATH.PAYMENT.ON_DEMAND.PAYMENT_SUMMARY
    : MODULE_PATH.PAYMENT.STANDARD.PAYMENT_SUMMARY;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;

          const setLocation = async () => {
            const response = await axios.get(
              `${MAPS_GEOCODE}?latlng=${latitude},${longitude}&key=${MAPS_API_KEY}`
            );

            if (response.data.results[0]) {
              const resData = response.data.results[0];
              setCurrentAddress(
                response.data?.plus_code?.compound_code || resData?.formatted_address
              );
            }
          };

          setLocation();
        },

        error => {
          console.error('Error getting user location:', error);
          message.info('Please turn on location', 3.0);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  const onClickPickup = () => {
    dispatch(setPointLocationPath('PickUp'));

    if (!isEmpty(pickUp?.senderDetails)) {
      dispatch(setLocationPath(pickUp?.address));
      dispatch(setPrevRoute('PickUp'));
      return navigate(MODULE_ADDRESS, {
        state: { showAutoComplete: true, currentAddress: currentAddress },
      });
    }

    navigate(MODULE_ADDRESS);
  };

  const onClickDropOff = () => {
    dispatch(setPointLocationPath('DropOff'));

    if (!isEmpty(dropOff?.recipientDetails)) {
      dispatch(setLocationPath(dropOff?.address));
      dispatch(setPrevRoute('DropOff'));
      return navigate(MODULE_ADDRESS, {
        state: { showAutoComplete: true },
      });
    }

    navigate(MODULE_ADDRESS);
  };

  return (
    <div className="form">
      <div className="card v3">
        <div className="delivery-type">
          <DeliveryType
            deliveryIcon={ON_DEMAND_ICON.MOTORCYCLE}
            className="on-demand"
            type="On-demand"
            refName="onDemand-view"
            deliverySubType="Receive Asap"
            deliveryType={deliveryType}
            handleSetDeliveryType={handleSetDeliveryType}
          />
          <DeliveryType
            deliveryIcon={ON_DEMAND_ICON.TRUCK}
            className="standard"
            type="Standard"
            refName="standard-view"
            deliverySubType="Receive in 2-3 days"
            deliveryType={deliveryType}
            handleSetDeliveryType={handleSetDeliveryType}
          />
        </div>
        <div className="courier-logo-list">
          <div className="courier-logo-list-container">
            <span>{`Book ${transFormDeliveryType()} couriers`}</span>
            <CourierLogos />
          </div>
        </div>
        <div className="form__input">
          <div className="map-marker">
            <img src={startMapMarker} alt="end-map-marker" />
            {/* <div className="vertical-line"></div> */}
            <img src={endMapMarker} alt="start-map-marker" />
          </div>
          <div className="input-field">
            {isEmpty(pickUp?.senderDetails?.fullName) ? (
              <input
                className="pickup-input"
                placeholder="Pickup location"
                defaultValue=""
                value={pickUp?.senderDetails?.fullName || ''}
                onClick={() => onClickPickup()}
                readOnly
              />
            ) : (
              <div className="user-info" onClick={() => onClickPickup()}>
                <span className="no-wrap mlLbl-base">{Ellips(pickUp?.senderDetails?.fullName)}</span>
                <span className="address-info cntnt-caption">{pickUp.address}</span>
              </div>
            )}
            {isEmpty(dropOff?.recipientDetails?.fullName) ? (
              <input
                className="dropoff-input"
                placeholder="Drop-off location"
                defaultValue=""
                value={dropOff?.recipientDetails?.fullName || ''}
                onClick={() => onClickDropOff()}
                readOnly
              />
            ) : (
              <div
                className="user-info"
                onClick={() => {
                  onClickDropOff();
                }}
              >
                <span className="no-wrap mlLbl-base">{Ellips(dropOff?.recipientDetails?.fullName)}</span>
                <span className="address-info cntnt-caption">{dropOff.address}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
