import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import AddBookmark from '../../../../shared/assets/images/OndemandDelivery/bookmark_add.png';
import EditIcon from '../../../../shared/assets/images/OndemandDelivery/edit.png';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import IconAdd from '../../../../shared/assets/svg/home-booking/icon_plus.svg';

import { setKey, setDefaults, geocode, RequestType } from 'react-geocode';
import {
  MAPS_API_KEY,
  POINT_STATE,
  MAPS_GEOCODE,
  zeroKm,
} from '../../../../shared/utils/enums/AppConstants';
import {
  setCourier,
  setDropOff as setOndemandDropOff,
  setLocation as setOndemandLocation,
  setLocationReference as setOndemandLocationReference,
  setPickUp as setOndemandPickUp,
  setPointLocation as setOnDemandPointLocation,
} from '../../../../shared/redux/onDemand/actions';
import {
  setDropOff as setStandardDropOff,
  setLocation as setStandardLocation,
  setPickUp as setStandardPickUp,
  setPointLocation as setStandardPointLocation,
} from '../../../../shared/redux/standard/actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  setSelectedAddress,
  fetchListOfAddress,
} from '../../../../shared/redux/addressBook/actions';
import { setLoader } from '../../../../shared/redux/home/actions';
import { isEmpty } from '../../../../shared/utils/helpers/purefunctions';
import IconSearch from '../../../../shared/assets/svg/home-booking/icon_search.svg';
import IconPinLocation from '../../../../shared/assets/svg/home-booking/icon_pin_location.svg';
import IconClock from '../../../../shared/assets/svg/home-booking/icon_clock.svg';
import IconAddAddress from '../../../../shared/assets/svg/home-booking/icon_add_address.svg';

const SelectPickupAddress = ({ addressList, user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [currentAddress, setCurrentAddress] = useState('');
  const [currentLocation, setCurrentLocation] = useState(null);
  const [viewAll, setViewAll] = useState(2);

  const onDemandProps = useSelector(state => state.onDemandReducer);

  const { formTab } = useSelector(state => state.homeReducer);
  // const standardProps = useSelector(state => state.standardReducer);

  // const { formTab } = useSelector(state => state.homeReducer);
  // const deliveryType = 'onDemand-view';
  // const isOndemand = formTab === deliveryType;

  // const deliveryProps = isOndemand ? onDemandProps : standardProps;

  const { pickup = {}, dropOff = {}, pointLocation } = onDemandProps;

  const { senderDetails = {} } = pickup;
  const { recipientDetails = {} } = dropOff;

  const isOndemand = 'onDemand-view' === formTab;
  const setPointLocationPath = isOndemand ? setOnDemandPointLocation : setStandardPointLocation;

  setDefaults({
    key: MAPS_API_KEY,
  });

  setKey(MAPS_API_KEY);

  useEffect(() => {
    if (location?.state?.currentAddress) {
      setCurrentAddress(location.state.currentAddress);
    } else if (onDemandProps?.location) {
      setCurrentAddress(onDemandProps?.location);
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;

            const setLocation = async () => {
              const response = await axios.get(
                `${MAPS_GEOCODE}?latlng=${latitude},${longitude}&key=${MAPS_API_KEY}`
              );

              if (response.data.results[0]) {
                const resData = response.data.results[0];
                setCurrentAddress(
                  response.data?.plus_code?.compound_code || resData?.formatted_address
                );
              }
            };

            setLocation();
          },

          error => {
            message.info('Please turn on location', 3.0);
          }
        );
      } else {
        // console.error('Geolocation is not supported by this browser.');
      }
    }
    fetchAddress();
  }, [fetchListOfAddress]);

  useEffect(() => {}, [addressList]);

  const fetchAddress = () => {
    dispatch(fetchListOfAddress(user.id));
    dispatch(setLoader(false));
  };

  const onClickCurrentAddress = (currentAddress) => {
    // dispatch(setPointLocationPath('DropOff'));
    navigate(MODULE_PATH.ADDRESS.ON_DEMAND.PICKUP_DETAILS, {
      state: { showAutoComplete: true, isFromCurrentAddress: true, recenltyUsed: true, ...location.state },
    });
    dispatch(setOndemandLocation(currentAddress));
  };

  const onClickSavedAddress = savedAddress => {
    if (pointLocation === 'PickUp') {
      let setPickUpAdd = {
        latitude: savedAddress.latitude,
        longitude: savedAddress.longitude,
        city: savedAddress.city,
        province: savedAddress.province,
        address: savedAddress.address,
        senderDetails: {
          ...senderDetails,
          fullName: savedAddress.fullName,
          unitFloorHouse: savedAddress.unitFloorHouse,
          contactNo: savedAddress.contactNo[0]
            ? `0${savedAddress.contactNo}`
            : savedAddress.contactNo,
          address: savedAddress.address,
          city: savedAddress.city,
          province: savedAddress.province,
        },
      };
      dispatch(setOndemandPickUp(setPickUpAdd));
      dispatch(setOndemandLocation(savedAddress.address));
      if (savedAddress.address !== onDemandProps.pickUp?.address) {
        dispatch(setCourier({}));
      }
    }
    if (pointLocation === 'DropOff') {
      let setDropOffAdd = {
        latitude: savedAddress.latitude,
        longitude: savedAddress.longitude,
        city: savedAddress.city,
        province: savedAddress.province,
        address: savedAddress.address,
        recipientDetails: {
          ...recipientDetails,
          fullName: savedAddress.fullName,
          unitFloorHouse: savedAddress.unitFloorHouse,
          contactNo: savedAddress.contactNo[0]
            ? `0${savedAddress.contactNo}`
            : savedAddress.contactNo,
          address: savedAddress.address,
          city: savedAddress.city,
          province: savedAddress.province,
        },
      };
      dispatch(setOndemandDropOff(setDropOffAdd));
      dispatch(setOndemandLocation(savedAddress.address));
      if (savedAddress.address !== onDemandProps.pickUp?.address) {
        dispatch(setCourier({}));
      }
    }
    navigate(MODULE_PATH.HOME);
  };

  useEffect(() => {
    // Check if geolocation is supported by the browser
    if ('geolocation' in navigator) {
      // Request current position
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ latitude, longitude });
        },
        error => {
          // console.error('Error getting user location:', error);
          // Handle error, such as displaying a message to the user
        }
      );
    } else {
      // console.error('Geolocation is not supported by this browser.');
      // Handle lack of geolocation support, such as displaying a message to the user
    }
  }, []);

  const RecentAddress = () => {
    return (
      <>
        {addressList.length > 0 &&
          addressList
            .filter(filteredAddress => filteredAddress.isSavePlaces === true)
            .sort((a, b) => b.timestamp - a.timestamp)
            .map(
              (address, index) =>
                index === 0 && (
                  <div
                    className={`current__location ${hideCardForInputSearch}`}
                    onClick={() => onClickSavedAddress(address)}
                  >
                    <span className='lbl-base'>Recently Used</span>
                    <div className="current-address-info">
                      <img src={IconClock} alt="Clock" />
                      <div className="address-value">
                        <span className='lbl-base'>{address?.addressType || address?.province}</span>
                        <span className='cntnt-caption'>
                          {address.unitFloorHouse} {address.address}
                        </span>
                      </div>
                      <img src={IconAddAddress} alt="bookmark add" />
                    </div>
                  </div>
                )
            )}
      </>
    );
  };

  const RecentUsedAddress = () => {
  const recentAddressList = location?.state?.orders || []

    return (
      <>
        {recentAddressList.length > 0 &&
          recentAddressList
            .sort((a, b) => b.timestamp - a.timestamp)
            .map(
              (address, index) =>
                (
                  <div
                    className={`current__location ${hideCardForInputSearch} recently`}
                    onClick={() => onClickCurrentAddress(address?.deliveryDetails?.deliveryAddress)}
                  >
                    <div className="current-address-info 1">
                      <img src={IconClock} alt="Clock" />
                      <div className="address-value">
                        <span className='lbl-base'>{address?.deliveryDetails?.customerName}</span>
                        <span className='cntnt-caption'>
                          {address?.deliveryDetails?.deliveryAddress}
                        </span>
                      </div>
                    </div>
                  </div>
                )
            )}
      </>
    );
  };

  const onClickSearchLocation = () => {
    dispatch(setOndemandLocationReference(false));
    navigate(MODULE_PATH.ADDRESS.ON_DEMAND.SEARCH_LOCATION);
  };

  const handleUpdate = obj => {
    dispatch(setSelectedAddress(obj));
    navigate(MODULE_PATH.ADDRESS_BOOK.FORM, { state: { addressBookForm: 'Edit', ...location.state } });
  };

  const handleViewAll = () => {
    setViewAll(addressList.length);
  };

  const handleSavedAddress = address => {
    if (
      isEmpty(address?.province) ||
      isEmpty(address?.city) ||
      isEmpty(address?.latitude) ||
      isEmpty(address?.longitude)
    ) {
      return message.info('Please update address. Missing required fields.', 3.0);
    }
    if (address?.latitude === zeroKm.latitude && address?.longitude === zeroKm.longitude) {
      return message.info('Please update pin location.', 3.0);
    }

    return onClickSavedAddress(address);
  };

  const hideCardForInputSearch = '';
  const HOME_PATH = pointLocation === 'DropOff' ? MODULE_PATH.HOME : MODULE_PATH.HOME;

  const isNotRecentlyUsed = !location?.state?.isRecentlyUsed;
  return (
    <div className="container__select__address">
      <div className="container__header">
        <Button
          className="back-button"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(HOME_PATH)}
        />

        <span className='lbl-base'>{isNotRecentlyUsed ? 'Search by places' : 'Recently Used'}</span>
      </div>
      {isNotRecentlyUsed && (
        <div className="search-location">
          <img src={IconSearch} alt="search" />
          <input placeholder={POINT_STATE[pointLocation]} onClick={() => onClickSearchLocation()} />
        </div>
      )}
      {isNotRecentlyUsed ? (
        !isEmpty(currentAddress) ? (
          <div className={`current__location ${hideCardForInputSearch}`}>
            <span className='lbl-base'>Suggested</span>
            <div className="current-address-info">
              <img src={IconPinLocation} alt="address pin" />
              <div
                className="address-value"
                onClick={() => currentAddress !== '' && onClickCurrentAddress(currentAddress)}
              >
                <span className='lbl-base'>Current Location</span>
                <span className='cntnt-caption'>{currentAddress}</span>
              </div>
              <img src={IconAddAddress} alt="bookmark add" />
            </div>
          </div>
        ) : (
          <RecentAddress />
        )
      ) : null}

      {isNotRecentlyUsed && (
        <div className={`title ${hideCardForInputSearch}`}>
          <span className='lbl-base '>Address Book</span>
          <span
            onClick={() => handleViewAll()}
            className={`${addressList.length <= 0 ? 'disable' : ''} lbl-base `}
          >
            View all
          </span>
        </div>
      )}
      {isNotRecentlyUsed && (
        <div className={`saved__places ${hideCardForInputSearch}`}>
          {addressList.length > 0 &&
            addressList
              .filter(filteredAddress => filteredAddress.isSavePlaces === true)
              .sort((a, b) => b.timestamp - a.timestamp)
              .map(
                (address, index) =>
                  index <= viewAll && (
                    <div className="content">
                      <img src={AddBookmark} alt="bookmark" />
                      <div
                        className="address-data"
                        key={index}
                        onClick={() => handleSavedAddress(address)}
                      >
                        <span className='lbl-base'>{address?.addressType || address?.province}</span>
                        <span className='cntnt-caption'>
                          {address.unitFloorHouse} • {address.address}
                        </span>
                        <span className='cntnt-caption'>
                          {address.fullName} • {`+63 ${address.contactNo}`}
                        </span>
                        {index === 0 && <span>Recently Used</span>}
                      </div>
                      <img src={EditIcon} alt="Edit" onClick={() => handleUpdate(address)} />
                    </div>
                  )
              )}

          <div className="add-new-address">
            <img src={IconAdd} alt="add address btn" />
            <div
              className="address-data"
              onClick={() =>
                navigate(MODULE_PATH.ADDRESS_BOOK.FORM, {
                  state: { addressBookForm: 'Add New', isSavePlaces: true, ...location.state },
                })
              }
            >
              <span className='lbl-base'>Add New</span>
              <span className='cntnt-caption'>Save your favorite places</span>
            </div>
          </div>
        </div>
      )}

      { !isNotRecentlyUsed && (
        <RecentUsedAddress />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  addressList: state.addressReducer.listOfAddress,
  recentlyAddress: state.addressReducer.recentlyAddress,
  user: state.usersReducer.currentUser,
  propsPickUp: state.onDemandReducer.pickUp,
});

export default connect(mapStateToProps, null)(SelectPickupAddress);
