import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { message } from 'antd';

import StickyHeader from '../../../../shared/components/StickyHeader';
import FormInput from '../../../../shared/components/FormInput';
import SubmitButton from '../../../../shared/components/SubmitButton';
import ProvinceCityBarangay from '../../../../shared/components/ProvinceCityBarangay';

import { MODULE_PATH } from '../../../../shared/constants/Module';
import { REF, INITIAL_STATE } from '../../../../shared/constants/AddressBook';

import { setSelectedAddress } from '../../../../shared/redux/addressBook/actions';

import {
  isEmpty,
  isValidPhoneNumber,
  validateEmail,
  cleanAdress,
} from '../../../../shared/utils/helpers/purefunctions';
import AddressBookDAO from '../../../../shared/utils/dao/AddressBook';
import Localities from '../../../../shared/utils/enums/Philippines.json';
import { ICON } from '../../../../shared/utils/enums/AppIcons';
import { zeroKm } from '../../../../shared/utils/enums/AppConstants';
import { setLoader } from '../../../../shared/redux/home/actions';

import FooterBtn from '../../../../shared/components/FooterBtn';

const AddressBookForm = ({ user, setSelectedAddress, selectedAddress, onSetLoader }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [addressDetails, setAddressDetails] = useState(INITIAL_STATE);
  const [inputFocus, setInputFocus] = useState(null);
  const [showAddressDrawer, setShowAddressDrawer] = useState(false);
  const [provinceOptions, setProvinceOptions] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const { formTab, prevRoute } = useSelector(state => state.homeReducer);

  // sticker header props

  let PATH = MODULE_PATH.ADDRESS_BOOK.MAIN;
  if ((formTab === 'onDemand-view' && prevRoute === 'PickUp') || prevRoute === 'DropOff') {
    PATH = MODULE_PATH.ADDRESS.ON_DEMAND.SELECT_ADDRESS;
  }
  if (formTab === 'standard-view') {
    PATH = MODULE_PATH.STANDARD.SENDER_DETAILS;
  }
  if (formTab === 'address-book') {
    PATH = MODULE_PATH.ADDRESS_BOOK.MAIN;
  }

  const prevNavigate = {
    path: PATH,
    state: {},
    setAddressValue: {},
  };

  const {
    addressType,
    unitFloorHouse,
    bldgStreet,
    provinceCityBarangay,
    province,
    city,
    barangay,
    postalCode,
    landmark,
    contactNo,
    fullName,
    emailAddress,
    notesToRider,
  } = addressDetails;

  const _unitFloorHouse = !isEmpty(unitFloorHouse);
  const _bldgStreet = !isEmpty(bldgStreet);
  const _provinceCityBarangay = !isEmpty(provinceCityBarangay);
  const _postalCode = !isEmpty(postalCode);
  const _landmark = !isEmpty(landmark);

  const _contactNo = !isEmpty(contactNo);
  const _fullName = !isEmpty(fullName);
  const _emailAddress = !isEmpty(emailAddress);
  const _notesToRider = !isEmpty(notesToRider);

  const hasAddress =
    _unitFloorHouse || _bldgStreet || _provinceCityBarangay || _postalCode || _landmark;
  const hasContact = _contactNo || _fullName || _emailAddress || _notesToRider;

  useEffect(() => {
    onSetLoader(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      !isEmpty(provinceOptions) && onSetLoader(false);
    }, 1000);
  }, [provinceOptions]);

  useEffect(() => {
    const PSGC = Localities;
    const province = [];
    Object.keys(PSGC).map(item => {
      const list = PSGC[item].province_list;
      Object.keys(list).map(name => {
        province.push({
          name,
          child: list[name].municipality_list,
        });
      });
    });

    setProvinceOptions({ types: 'province', data: province });
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedAddress)) {
      setAddressDetails({
        ...selectedAddress,
        addressType: selectedAddress?.addressType || '',
        unitFloorHouse: selectedAddress?.unitFloorHouse || '',
        bldgStreet: selectedAddress?.bldgStreet || '',
        provinceCityBarangay: cleanAdress(
          selectedAddress?.province || null,
          selectedAddress?.city || null,
          selectedAddress?.barangay || null
        ),
        postalCode: selectedAddress?.postalCode || '',
        landmark: selectedAddress?.landmark || '',
        contactNo: formatNumber(selectedAddress?.contactNo || ''),
        fullName: selectedAddress?.fullName || '',
        emailAddress: selectedAddress?.emailAddress || '',
        notesToRider: selectedAddress?.notesToRider || '',
      });
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (!isEmpty(provinceOptions)) {
      let __city = [];
      if (!isEmpty(selectedAddress?.province)) {
        const data = provinceOptions.data.filter(e =>
          e.name.toLowerCase().includes((selectedAddress?.province).toLowerCase())
        );

        if (data.length) {
          const childData = data[0].child;
          Object.keys(childData).map(name => {
            const child = childData[name].barangay_list;
            __city.push({
              name,
              child,
            });
          });
        }
      }

      let _brgy = [];
      if (!isEmpty(__city)) {
        const brgy = __city.filter(e =>
          e.name.toLowerCase().includes((selectedAddress?.city).toLowerCase())
        );
        if (brgy.length) {
          _brgy = brgy[0].child;
        }
      }

      let brgyExist = !isEmpty(selectedAddress?.barangay);
      if (!isEmpty(_brgy) && brgyExist) {
        brgyExist = _brgy.includes((selectedAddress?.barangay).toUpperCase());
      }

      if (isEmpty(__city) || isEmpty(_brgy) || !brgyExist) {
        setAddressDetails({
          ...selectedAddress,
          province: '',
          city: '',
          barangay: '',
          provinceCityBarangay: '',
        });
      }
    }
  }, [provinceOptions]);

  useEffect(() => {
    const timeOutId = setTimeout(() => fieldValidations(), 1000);
    return () => clearTimeout(timeOutId);
  }, [addressDetails]);

  const setProvinceCityBrgy = provinceCityBrgy => {
    let address = {
      ...addressDetails,
      province: provinceCityBrgy.province,
      city: provinceCityBrgy.city,
      barangay: provinceCityBrgy.barangay,
      provinceCityBarangay: cleanAdress(
        provinceCityBrgy?.province,
        provinceCityBrgy?.city,
        provinceCityBrgy?.barangay
      ),
    };

    if (
      provinceCityBrgy.province.toLowerCase() !== (addressDetails?.province).toLowerCase() ||
      provinceCityBrgy.city.toLowerCase() !== (addressDetails?.city).toLowerCase() ||
      provinceCityBrgy.barangay.toLowerCase() !== (addressDetails?.barangay).toLowerCase()
    ) {
      address = {
        ...address,
        latitude: 0,
        longitude: 0,
      };
    }
    setAddressDetails(address);
    setSelectedAddress(address);
  };

  const showNotif = debounce(async (type, msg) => {
    message[type](msg);
  }, 500);

  const handlePinLocation = () => {
    setSelectedAddress({ ...addressDetails });
    navigate(MODULE_PATH.ADDRESS_BOOK.MAP, { state: location.state });
  };

  const clearAddress = () => {
    return setAddressDetails({
      ...addressDetails,
      unitFloorHouse: '',
      bldgStreet: '',
      provinceCityBarangay: '',
      province: '',
      city: '',
      barangay: '',
      postalCode: '',
      landmark: '',
    });
  };

  const clearContact = () => {
    return setAddressDetails({
      ...addressDetails,
      contactNo: '',
      fullName: '',
      emailAddress: '',
      notesToRider: '',
    });
  };

  const areAllFieldsFilled = obj => {
    return Object.keys(obj).every(key => Boolean(obj[key]));
  };

  const handleClearField = field => {
    setIsClicked(true);
    let address = {};
    if (field === 'provinceCityBarangay') {
      address = { ...address, province: '', city: '', barangay: '' };
    }

    setSelectedAddress({ ...addressDetails, [field]: '', ...address });
    setAddressDetails({ ...addressDetails, [field]: '', ...address });
    setIsClicked(false);
  };

  const handleInputFocus = fieldName => {
    !isClicked && setInputFocus(fieldName);
  };

  const handleRemoveInputFocus = () => {
    if (!isClicked && inputFocus === 'contactNo' && !isEmpty(addressDetails?.contactNo)) {
      let newValue = addressDetails.contactNo.replace(/ /g, '');
      const validatePhone = isValidPhoneNumber(addressDetails.contactNo);
      if (validatePhone.isValid) {
        const input = validatePhone.value;
        var zip = input.substring(0, 3);
        var middle = input.substring(3, 6);
        var last = input.substring(6, 10);
        newValue = `${zip} ${middle} ${last}`;
      }
      setAddressDetails({ ...addressDetails, contactNo: newValue });
      setSelectedAddress({ ...addressDetails, contactNo: newValue });
    }

    setInputFocus(null);
  };

  const formatNumber = num => {
    const validatePhone = isValidPhoneNumber(num);
    if (validatePhone.isValid) {
      const input = validatePhone.value;
      var zip = input.substring(0, 3);
      var middle = input.substring(3, 6);
      var last = input.substring(6, 10);
      return `${zip} ${middle} ${last}`;
    }
    return num;
  };

  const handleAddressChange = (field, value) => {
    const fieldLimit = ['unitFloorHouse', 'bldgStreet', 'notesToRider'];
    if (fieldLimit.includes(field)) {
      if (value.length > 120) return;
    }

    setAddressDetails({ ...addressDetails, [field]: value });
    // setSelectedAddress({ ...addressDetails, contactNo: newValue });
  };

  const fieldValidations = () => {
    if (inputFocus === 'contactNo') {
      const validatePhone = isValidPhoneNumber(addressDetails.contactNo);
      if (!validatePhone.isValid) {
        showNotif('error', 'Please enter a valid contact number.');
      }
    }

    if (inputFocus === 'emailAddress') {
      if (!validateEmail(addressDetails.emailAddress)) {
        showNotif('error', 'Invalid email format. Please try again.');
      }
    }
  };

  const checkForm = () => {
    const requiredFields = {
      unitFloorHouse,
      bldgStreet,
      provinceCityBarangay,
      postalCode,
      fullName,
      contactNo,
    };

    const validatePhone = addressDetails?.contactNo
      ? isValidPhoneNumber(addressDetails.contactNo).isValid
      : true;
    const validateEmailAddress = addressDetails?.emailAddress
      ? validateEmail(addressDetails.emailAddress)
      : true;

    return areAllFieldsFilled(requiredFields) && validatePhone && validateEmailAddress;
  };

  const checkAddress = () => {
    const requiredFields = {
      unitFloorHouse,
      bldgStreet,
      provinceCityBarangay,
      province,
      city,
      barangay,
      postalCode,
    };

    return areAllFieldsFilled(requiredFields);
  };

  const pushAddress = () => {
    if (!checkForm()) return;
    const validateLatLng = (isEmpty(selectedAddress?.latitude) && isEmpty(selectedAddress?.longitude)) || (selectedAddress?.latitude === zeroKm.latitude && selectedAddress?.longitude === zeroKm.longitude)

    if (validateLatLng) {
      return message.info('Please add pin location.', 3.0);
    }

    const addressBookDao = new AddressBookDAO();
    if (location?.state.addressBookForm === 'Edit') {
      addressBookDao.editAddress(selectedAddress.id, user.id, { ...addressDetails });
    } else {
      if (location?.state.isSavePlaces) {
        addressBookDao.saveAddress(user.id, { ...addressDetails, isSavePlaces: true });
      } else {
        addressBookDao.saveAddress(user.id, { ...addressDetails });
      }
    }
    setSelectedAddress({});
    formTab === 'standard-view'
      ? navigate(MODULE_PATH.STANDARD.SELECT_ADDRESS, {})
      : navigate(MODULE_PATH.ADDRESS_BOOK.MAIN, {});
  };

  return (
    <>
      <StickyHeader
        onPrevNavigate={prevNavigate}
        title={`${location?.state.addressBookForm || 'Add New'} Address`}
      />

      <div className="content-container v4-addressbook">
        <div className="address-book-card-form">
          <div className="card-box">
            <div className="card-box-label lbl-large">Address Name</div>
            <div className="card-box-fields">
              <FormInput
                value={addressType}
                onChange={value => handleAddressChange(REF.ADDRESS_TYPE, value)}
                onClick={() => handleClearField(REF.ADDRESS_TYPE)}
                onFocus={() => handleInputFocus(REF.ADDRESS_TYPE)}
                inputFocusName={inputFocus === REF.ADDRESS_TYPE}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="e.g. Home, Gym, Office"
                className="no-border-label"
              />
            </div>
          </div>
          <div className="card-box">
            <div className="card-box-label">
              <span className='lbl-large'>Address</span>
              {hasAddress && (
                <span className="clear-form" onClick={() => clearAddress()}>
                  Clear form
                </span>
              )}
            </div>
            <div className="card-box-fields">
              <FormInput
                value={unitFloorHouse}
                onChange={value => handleAddressChange(REF.UNIT_FLOOR, value)}
                onClick={() => handleClearField(REF.UNIT_FLOOR)}
                onFocus={() => handleInputFocus(REF.UNIT_FLOOR)}
                inputFocusName={inputFocus === REF.UNIT_FLOOR}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Unit/Floor/House No"
                isReqOptional="req"
                hasCounter={true}
              />
              <FormInput
                value={bldgStreet}
                onChange={value => handleAddressChange(REF.BLG_STREET, value)}
                onClick={() => handleClearField(REF.BLG_STREET)}
                onFocus={() => handleInputFocus(REF.BLG_STREET)}
                inputFocusName={inputFocus === REF.BLG_STREET}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Building/Street"
                isReqOptional="req"
                hasCounter={true}
              />
              <FormInput
                value={provinceCityBarangay}
                onChange={value => handleAddressChange(REF.PROV_CITY_BRGY, value)}
                onClick={() => handleClearField(REF.PROV_CITY_BRGY)}
                onFocus={() => handleInputFocus(REF.PROV_CITY_BRGY)}
                inputFocusName={inputFocus === REF.PROV_CITY_BRGY}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Province/City/Barangay"
                isReqOptional="req"
                onSetShow={() => setShowAddressDrawer(true)}
                className="Format-Places"
              />
              <FormInput
                value={postalCode}
                onChange={value => handleAddressChange(REF.POSTAL_CODE, value)}
                onClick={() => handleClearField(REF.POSTAL_CODE)}
                onFocus={() => handleInputFocus(REF.POSTAL_CODE)}
                inputFocusName={inputFocus === REF.POSTAL_CODE}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Postal Code"
                isReqOptional="req"
              />
              <FormInput
                value={landmark}
                onChange={value => handleAddressChange(REF.LANDMARK, value)}
                onClick={() => handleClearField(REF.LANDMARK)}
                onFocus={() => handleInputFocus(REF.LANDMARK)}
                inputFocusName={inputFocus === REF.LANDMARK}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Landmark"
                isReqOptional="opt"
              />
              {!isEmpty(selectedAddress?.latitude) &&
              !isEmpty(selectedAddress?.longitude) &&
              checkAddress() && !(selectedAddress?.latitude === zeroKm.latitude && selectedAddress?.longitude === zeroKm.longitude) ? (
                <div className="address-set-points" onClick={() => handlePinLocation()}>
                  <span>
                    <img src={ICON.CENTER_MAP} />
                  </span>
                  <span>
                    <span>{selectedAddress.addressType}</span>
                    <span>{`${selectedAddress.address}, Philippines`}</span>
                  </span>
                </div>
              ) : (
                <SubmitButton
                  isProceedValue={checkAddress()}
                  onClick={() => handlePinLocation()}
                  hasIcon="MAP"
                  name="Add Pin Location"
                />
              )}
            </div>
          </div>
          <div className="card-box">
            <div className="card-box-label">
              <span className='lbl-large'>Contact Person</span>
              {hasContact && (
                <span className="clear-form" onClick={() => clearContact()}>
                  Clear form
                </span>
              )}
            </div>
            <div className="card-box-fields">
              <FormInput
                value={contactNo}
                onChange={value => handleAddressChange(REF.CONTACT_NO, value)}
                onClick={() => handleClearField(REF.CONTACT_NO)}
                onFocus={() => handleInputFocus(REF.CONTACT_NO)}
                inputFocusName={inputFocus === REF.CONTACT_NO}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Phone Number"
                suffixIcon="PHONE_NO"
                prefixOn="PHONE_NO"
                prefixIcon=""
                validatePhone={isValidPhoneNumber(contactNo)?.isValid}
                isReqOptional="req"
              />
              <FormInput
                value={fullName}
                onChange={value => handleAddressChange(REF.FULLNAME, value)}
                onClick={() => handleClearField(REF.FULLNAME)}
                onFocus={() => handleInputFocus(REF.FULLNAME)}
                inputFocusName={inputFocus === REF.FULLNAME}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Contact Name"
                isReqOptional="req"
              />
              <FormInput
                value={emailAddress}
                onChange={value => handleAddressChange(REF.EMAIL, value)}
                onClick={() => handleClearField(REF.EMAIL)}
                onFocus={() => handleInputFocus(REF.EMAIL)}
                inputFocusName={inputFocus === REF.EMAIL}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Email Address"
              />
              <FormInput
                value={notesToRider}
                onChange={value => handleAddressChange(REF.NOTES_TO_RIDER, value)}
                onClick={() => handleClearField(REF.NOTES_TO_RIDER)}
                onFocus={() => handleInputFocus(REF.NOTES_TO_RIDER)}
                inputFocusName={inputFocus === REF.NOTES_TO_RIDER}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Note to Rider"
                hasCounter={true}
              />
            </div>
          </div>
        </div>
        <ProvinceCityBarangay
          provinceOpt={provinceOptions}
          province={province}
          city={city}
          barangay={barangay}
          show={showAddressDrawer}
          onShowDrawer={show => setShowAddressDrawer(show)}
          onSetProvinceCityBrgy={pcb => setProvinceCityBrgy(pcb)}
        />
      </div>

      <FooterBtn
            className={`new-address-book ${!checkForm() ? 'disabled' : ''}`}
            childClass="radius"
            label={`${
              location?.state.addressBookForm === 'Edit' ? 'Save Changes' : 'Submit Address'
            }`}
            onClick={() => pushAddress()}
          />
    </>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedAddress,
      onSetLoader: setLoader,
    },
    dispatch
  );

const mapStateToProps = state => ({
  user: state.usersReducer.currentUser,
  selectedAddress: state.addressReducer.selectedAddress,
});

export default connect(mapStateToProps, matchDispatchToProps)(AddressBookForm);
