import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import kgImage from '../assets/images/OndemandDelivery/kg.svg';
import StickyHeader from './StickyHeader';
import { ICON } from '../utils/enums/AppIcons';
import { MODULE_PATH } from '../constants/Module';
import { COURIER_VEHICLES, FOOD_PANDA, PANDAGO } from '../utils/enums/Providers';
import { setCourier, setCurrentCourierRates } from '../redux/onDemand/actions';
import { setPrevRoute } from '../redux/home/actions';
import DPHDAO from '../utils/dao/DPH';

import { BOOK_ANY, BORZO_VEHICLES } from '../utils/enums/AppConstants';
import { COURIERS, CONVENIENCE_FEE } from '../utils/enums/DeliveryConstants';
import arrowRight from '../assets/images/OndemandDelivery/arrow_right.svg';
import {
  formatBills,
  filterCourierPartner,
  capitalizeName,
  formatUnderscoredText,
} from '../utils/helpers/purefunctions';
import { COURIER_IMAGES } from '../utils/enums/CourierImages';
import TWO_WHEEL from '../assets/images/Couriers Images/icon_base_mini_motorcycle.svg';
import FOUR_WHEEL from '../assets/images/Couriers Images/icon_base_mini_vehicle.svg';
import { Skeleton, Space, Tour, message } from 'antd';
import BookAnyHelp from '../../app/body/OndemandDelivery/elements/BookAnyHelp';
import EMPTY_COURIER from '../../shared/assets/svg/courier_illustration.svg';
import { onDemandPayload } from '../utils/helpers/Payload';
import { useQuery } from '@tanstack/react-query';
import Tap from '../../shared/assets/svg/home-booking/Tap.svg';

const CouriersList = () => {
  const ref1 = useRef(null);
  const [showHelp, setShowHelp] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const selectedVehicle = useSelector(state => state.onDemandReducer.vehicleType);
  const onDemandProps = useSelector(state => state.onDemandReducer);
  const userInfo = useSelector(state => state.usersReducer);
  const isBookAnyUser = userInfo.currentUser.advancedCourierMode;
  const [showBookAny, setShowBookAny] = useState(!isBookAnyUser);
  const [onDemandCouriers, setOnDemandCouriers] = useState([]);

  const [isEnabledQueryPartners, setIsEnabledQueryPartners] = useState(false);

  const prevNavigate = {
    label: 'Choose Courier',
    imgPath: ICON.ON_DEMAND,
    path: MODULE_PATH.HOME,
  };

  const handleSelectCourier = data => {
    let newData = {};
    if (!data.rate) return;
    if (data.courier === PANDAGO.toUpperCase()) {
      newData = { ...data, courier: FOOD_PANDA };
      dispatch(setCourier(newData));
      navigate(MODULE_PATH.HOME, { state: location?.state });
      return;
    }
    dispatch(setCourier(data));
    dispatch(setPrevRoute('couriersList'));
    navigate(MODULE_PATH.HOME, { state: location?.state });
  };

  const handleSelectBookAny = () => {
    const filteredOnDemandCouriers = onDemandCouriers?.filter(courier => courier?.rate);

    if (onDemandCouriers.length === 0) {
      message.info('Please wait while fetching couriers list', 3.0);
      return;
    }
    if (filteredOnDemandCouriers.length === 0) {
      message.info('No available couriers on your selected vehicle', 3.0);
      return;
    }
    const currentCourierRates = onDemandCouriers.reduce((rates, { courier, rate }) => {
      rates[courier] = rate;
      return rates;
    }, {});
    dispatch(setCurrentCourierRates(currentCourierRates));

    const highestRatedCourier = onDemandCouriers.reduce(
      (maxRate, obj) => (obj.rate > maxRate.rate ? obj : maxRate),
      onDemandCouriers[0]
    );
    dispatch(
      setCourier({
        courier: BOOK_ANY,
        rate: highestRatedCourier.rate,
        vehicleType: selectedVehicle.value,
      })
    );
    navigate(MODULE_PATH.HOME, { state: location?.state });
  };

  const onDemandPartners = onDemandProps.onDemandPartners;

  const { data } = useQuery({
    queryKey: ['getPartners'],
    queryFn: async () => {
      return await DPHDAO.getPartners();
    },
    enabled: isEnabledQueryPartners,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let onDemandQouteAPI;

        const courierPartnersData = await DPHDAO.getPartners();

        if (typeof onDemandPartners === 'undefined') {
          setIsEnabledQueryPartners(true);
          onDemandQouteAPI = await DPHDAO.getOnDemandQuotation(
            onDemandPayload(
              onDemandProps,
              filterCourierPartner(courierPartnersData, COURIERS),
              userInfo
            )
          );
        } else {
          onDemandQouteAPI = await DPHDAO.getOnDemandQuotation(
            onDemandPayload(
              onDemandProps,
              filterCourierPartner(courierPartnersData, COURIERS),
              userInfo
            )
          );
        }

        setOnDemandCouriers(onDemandQouteAPI);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const VehicleIcon = ({ value }) => {
    return <img src={'motorcycle' === value.toLowerCase() ? TWO_WHEEL : FOUR_WHEEL} alt={value} />;
  };

  const SkeletonLoaders = ({ count }) => (
    <>
      {Array.from({ length: count }, (_, i) => (
        <div className="courier-list loader" key={i}>
          <div className="courier-info">
            <Space>
              <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
              <Skeleton.Input
                block={true}
                active={true}
                size={'small'}
                style={{ width: '220px' }}
              />
            </Space>
          </div>
          <div className="courier-vehicle-type">
            <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
            <Space>
              <Skeleton.Input
                block={true}
                active={true}
                size={'small'}
                style={{ width: '250px' }}
              />
            </Space>
          </div>
        </div>
      ))}
    </>
  );

  const CourierList = ({ ondemandQoute }) => {
    const filteredOnDemandCouriers = ondemandQoute?.filter(courier => courier?.rate);

    if (filteredOnDemandCouriers.length === 0) {
      return (
        <div className="empty-courier">
          <div className="empty-courier-container">
            <div className="image">
              <img src={EMPTY_COURIER} alt="empty courier" />
            </div>
            <div className="content">No available couriers on your selected vehicle</div>
            <div className="sub-content">Please select another Vehicle Type</div>
          </div>
        </div>
      );
    }
    return (
      filteredOnDemandCouriers &&
      filteredOnDemandCouriers.map((order, index) => {
        let newCourier = order.courier === PANDAGO.toUpperCase() ? FOOD_PANDA : order.courier;
        let vehicleType;
        switch (order.courier) {
          case 'MRSPEEDY':
            vehicleType = BORZO_VEHICLES[order.vehicleType];
            break;
          case 'LALAMOVE':
            vehicleType = COURIER_VEHICLES.LALAMOVE.Vehicles[order.vehicleType];
            break;
          default:
            vehicleType = order.vehicleType;
        }
        const newVehicleType =
          order.vehicleType === 'MPV' ? vehicleType : formatUnderscoredText(vehicleType);
        return (
          <>
            <div className="couriers__list" key={index} onClick={() => handleSelectCourier(order)}>
              <div className="couriers__list__container">
                <img src={COURIER_IMAGES[newCourier]} alt="kg" />
                <div className="couriers__list__content">
                  <span className="couriers__info">
                    <span className="courier__name lbl-base">
                      {order.courier === 'FOOD_PANDA'
                        ? capitalizeName('pandago')
                        : capitalizeName(order.courier.toLowerCase())}
                    </span>

                    <span className="couriers__dimensions__type">
                      {order.error ? (
                        <span>{order.error}</span>
                      ) : (
                        <>
                          <div className="vehicle__info">
                            <VehicleIcon value={selectedVehicle.value} />
                            <span className="vehicle__name cntnt-small-caption">
                              {capitalizeName(newVehicleType.toLowerCase())}
                            </span>
                            <div className="vehicle__dimensions cntnt-small-caption">
                              <img src={kgImage} alt="kg" />
                              <span>
                                {capitalizeName(
                                  selectedVehicle.description.weightText.toLowerCase()
                                )}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </span>
                  </span>
                  <span className="courier__rate">
                    {order.error ? (
                      ''
                    ) : (
                      <span className='hdln-block'>{`P ${formatBills(
                        parseFloat(order.rate) + CONVENIENCE_FEE.REGULAR
                      )}`}</span>
                    )}
                  </span>
                </div>
                <span className="arrow-right">
                  <img src={arrowRight} alt="arrow-right" />
                </span>
              </div>
            </div>
          </>
        );
      })
    );
  };
  const steps = [
    {
      title: 'Easier Courier booking',
      description: 'Need help looking for a courier? Check out this option!',
      cover: (
        <div className="tour-delivery-type-description-bottom">
          <img src={Tap} alt="Tap to Continue" className="tap-to-continue-bottom" />
          <span>Top to Continue</span>
        </div>
      ),
      target: () => ref1.current,
    },
  ];

  return (
    <div className="courier__list__container" onClick={() => setShowBookAny(false)}>
      <StickyHeader title="Choose Courier" onPrevNavigate={prevNavigate} />
      <span className="list__header advance">
        <span>Advance</span>
        <span onClick={() => setShowHelp(true)} className='cntnt-base-bold'>
          Help & Perks <img src={ICON.HELP} alt="help" />
        </span>
      </span>
      <div className="book__any" onClick={() => handleSelectBookAny()}>
        <div ref={ref1} className="book__any__container">
          <img src={ICON.BOOK_ANY} alt="kg" />
          <div className="book__any__content">
            <span className='lbl-base'>Book Any Courier</span>
            <span className='cntnt-small-caption'>
              Choose this option and let us get the fastest courier that will accept your order!
            </span>
          </div>
          <span className="arrow-right">
            <img src={arrowRight} alt="arrow-right" />
          </span>
        </div>
      </div>

      <Tour
        placement={'bottom'}
        open={showBookAny}
        onClose={() => setShowBookAny(false)}
        motion={{ followScroll: true, offsetY: 30 }}
        steps={steps}
      />
      {/* <span className="list__header">Regular</span> */}
      {!onDemandCouriers.length ? (
        <>
          <div className="loading">
            <img src={ICON.ICON_BASE_INFO} alt="info" />
            <span>Fetching Couriers. Please Wait</span>
          </div>
          <SkeletonLoaders count={3} />
        </>
      ) : (
        <CourierList ondemandQoute={onDemandCouriers} />
      )}

      <BookAnyHelp show={showHelp} onSetShow={() => setShowHelp(false)} />
    </div>
  );
};

export default CouriersList;
