/* global my */
import { isEmpty } from 'lodash';
import { APP_ACCESS, GCASH_APP_ID } from '../../../shared/utils/enums/AppConstants';
import { setCurrentUser } from '../../redux/users/actions';
import Login from './Login';
import {
  setAppAccess,
  setAppDisclaimer,
  setAppLegend,
  setAppTourIdx,
  setAppViewAll,
} from '../../redux/app/actions';

class GCash {
  static async linkOut(url) {
    const result = await my.postMessage({ url, GCASH_APP_ID });
    return result;
  }

  static async setStorage(userDetails) {
    return my.setStorage({
      key: 'userInfo',
      data: userDetails,
      success: function () {},
    });
  }

  static async getStorage() {
    return new Promise((resolve, reject) => {
      my.getStorage({
        key: 'userInfo',
        success: function (res) {
          resolve(res.data);
        },
        fail: function (res) {
          my.alert({ content: res.errorMessage });
          reject(res.errorMessage);
        },
      });
    });
  }

  static runAppTest(dispatch) {
    return setTimeout(async () => {
      const userLogged = {
        firstName: 'BILLSPAY',
        lastName: 'UAT TESTING',
        gcashNumber: '09056628913',
        email: 'lizllante@gmail.com',
        id: '110700400000000000647412367',
        loggedIn: 1713795126000,
        advancedCourierMode: false,
        recordCreated: true,
        courierMode: 'regular',
        lastLoggedIn: 1713795083000,
        touredForm: true,
        touredLegend: true,
        touredViewAll: true,
      };

      dispatch(setAppAccess(APP_ACCESS.HOME));
      setTimeout(async () => {
        if (!userLogged?.recordCreated && !userLogged?.touredForm) {
          dispatch(setAppDisclaimer(true));
        }
        if (userLogged?.touredForm) {
          dispatch(setAppTourIdx(undefined));
        }

        dispatch(setAppDisclaimer(!userLogged?.touredForm ? true : undefined));
        dispatch(setAppLegend(!userLogged?.touredLegend ? true : undefined));
        dispatch(setAppViewAll(!userLogged?.touredViewAll ? true : undefined));
      }, 1000);
    }, 2000);
  }

  static runApp(dispatch) {
    let userInfo;

    const login = async gcashInfo => {
      const { authCode } = gcashInfo;

      const loginDao = new Login();

      const code = await loginDao.saveGcashAuthCode(authCode);
      userInfo = await loginDao.getAuthCode(code);
      const user = await loginDao.saveUser(userInfo?.userId, userInfo);

      dispatch(setCurrentUser(user));
      return user;
    };

    return setTimeout(async () => {
      await my.postMessage({ sendToMiniProgramFromWebApp: '1' });
      my.onMessage = async e => {
        if (!isEmpty(e?.code)) {
          const userLogged = await login(e?.code);
          if ('success' in userInfo) {
            dispatch(setAppAccess(APP_ACCESS.MAINTENANCE));
          } else {
            dispatch(setAppAccess(APP_ACCESS.HOME));
            setTimeout(async () => {
              if (!userLogged?.recordCreated && !userLogged?.touredForm) {
                dispatch(setAppDisclaimer(true));
              }
              if (userLogged?.touredForm) {
                dispatch(setAppTourIdx(undefined));
              }

              dispatch(setAppDisclaimer(!userLogged?.touredForm ? true : undefined));
              dispatch(setAppLegend(!userLogged?.touredLegend ? true : undefined));
              dispatch(setAppViewAll(!userLogged?.touredViewAll ? true : undefined));
            }, 1000);
          }
        } else {
          dispatch(setAppAccess(APP_ACCESS.UNAUTHORIZED));
        }
      };
    }, 2000);
  }
}
export default GCash;
