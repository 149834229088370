import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ChevronRight from '../../../../shared/assets/svg/my-accounts/chevronRight.svg';
import Orders from '../../../../shared/assets/svg/my-accounts/myAccountsOrders.svg';
import ContactUs from '../../../../shared/assets/svg/my-accounts/myAccountsAddressBook.svg';
import TermsAndConditions from '../../../../shared/assets/svg/my-accounts/myAccountsTermsAndConditions.svg';
import PrivacyPolicy from '../../../../shared/assets/svg/my-accounts/myAccountsPrivacyPolicy.svg';
import AboutUs from '../../../../shared/assets/svg/my-accounts/myAccountsAboutParcels.svg';
import CashBack from '../../../../shared/assets/svg/my-accounts/icon_cashback_v2.svg';

import { MODULE_PATH } from '../../../../shared/constants/Module';
import StickyHeader from '../../../../shared/components/StickyHeader';
import { setFormTab } from '../../../../shared/redux/home/actions';
import {
  formatPhoneNumber,
  capitalizeName,
  isEmpty,
} from '../../../../shared/utils/helpers/purefunctions';

const MyAccountsInfo = () => {
  const { MY_ACCOUNTS, ADDRESS_BOOK } = MODULE_PATH;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.usersReducer.currentUser);

  const name = `${user.firstName} ${user.lastName}`;

  const prevNavigate = {
    label: '',
    imgPath: '',
    path: MODULE_PATH.HOME,
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const goToAddressBook = () => {
    dispatch(setFormTab('address-book'));
    navigate(ADDRESS_BOOK.MAIN);
  };

  const goToTermsAndConditions = () => navigate(MY_ACCOUNTS.TERMS_CONDITIONS);
  const goToPrivacyPolicy = () => navigate(MY_ACCOUNTS.PRIVACY_POLICY);
  const goToAboutUs = () => navigate(MY_ACCOUNTS.ABOUT_US);

  const getInitial = name => {
    return name.charAt(0);
  };

  const initial = () => {
    return `${getInitial(user?.firstName)}${getInitial(user?.lastName)}`;
  };

  return (
    <div className="my-accounts-info-container">
      <StickyHeader
        title=""
        onPrevNavigate={prevNavigate}
        callback={() => dispatch(setFormTab('onDemand-view'))}
      />
      {!isEmpty(user) && (
        <div className="card-info">
          <div className="profile-avatar hdln-area">
            <span className="initial">{initial()}</span>
          </div>
          <div className="profile-info">
            <span className="name lbl-large">{capitalizeName(name)}</span>
            <span className="contact">{formatPhoneNumber(user.gcashNumber)}</span>
            <span className="email">{user.email}</span>
          </div>
        </div>
      )}
      <div className="card-info">
        <div className="rows" onClick={goToAddressBook}>
          <img src={ContactUs} className="icon" alt="right arrow" />
          <span className="title lbl-base">Address Book</span>
          <img src={ChevronRight} className="next-info" alt="arrow right" />
        </div>
        <div className="rows" onClick={goToTermsAndConditions}>
          <img src={TermsAndConditions} className="icon" alt="terms and conditions" />
          <span className="title lbl-base">Terms And Conditions</span>
          <img src={ChevronRight} className="next-info" alt="arrow right" />
        </div>
        <div className="rows" onClick={goToPrivacyPolicy}>
          <img src={PrivacyPolicy} className="icon" alt="privacy policy" />
          <span className="title lbl-base">Privacy Policy</span>
          <img src={ChevronRight} className="next-info" alt="arrow right" />
        </div>
        <div className="rows" onClick={goToAboutUs}>
          <img src={AboutUs} className="icon" alt="about us" />
          <span className="title lbl-base">About Us</span>
          <img src={ChevronRight} className="next-info" alt="arrow right" />
        </div>
      </div>
    </div>
  );
};

export default MyAccountsInfo;
