import WeightSVG from '../../assets/images/Icons/weight.svg';
import DimensionSVG from '../../assets/images/Icons/dimension.svg';

export const STANDARD_COURIERS = ['QUADX', 'XDE', 'NINJAVAN', 'CLIQNSHIP'];

export const PRODUCT_SIZES = [
  {
    sizeName: 'Small (S)',
    value: 'Small',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 3Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '24 x 36 x 0 in',
    },
  },
  {
    sizeName: 'Medium (M)',
    value: 'Medium',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 1Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '30 x 46 x 1 in',
    },
  },
  {
    sizeName: 'Large (L)',
    value: 'Large',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 10Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '39 x 51 x 10 in',
    },
  },
  {
    sizeName: 'Extra Large (XL)',
    value: 'Extra Large',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 6Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '46 x 30 x 23 in',
    },
  },
];

export const STANDARD_DIMENSIONS = {
  QUADX: {
    Small: {
      weightText: '26 x 34 x 1 in',
      dimensionText: 'Up to 3kg',
    },
    Medium: {
      weightText: '30 x 46 x 1 in',
      dimensionText: 'Up to 5kg',
    },
    Large: {
      weightText: '39 x 51 x 10 in',
      dimensionText: 'Up to 3kg',
    },
    Extra_Large: {
      weightText: '46 x 30 x 23 in',
      dimensionText: 'Up to 6kg',
    },
    Own_Pack: {
      weightText: '1 x 1 x 1 in',
      dimensionText: 'Up to 1kg',
    },
  },
  NINJAVAN: {
    Medium: {
      weightText: '10 x 10 x 1 in',
      dimensionText: 'Up to 3kg',
    },
    Large: {
      weightText: '12 x 16.5 x 3 in',
      dimensionText: 'Up to 6kg',
    },
  },
  XDE: {
    Small: {
      weightText: '11 x 6 x 1 in',
      dimensionText: 'Up to 1kg',
    },
    Medium: {
      weightText: '14 x 9 x 1 in',
      dimensionText: 'Up to 2kg',
    },
    Large: {
      weightText: '18 x 11.5 x 1 in',
      dimensionText: 'Up to 3kg',
    },
    Extra_Large: {
      weightText: '11 x 6 x 1 in',
      dimensionText: 'Up to 1kg',
    },
  },
  CLIQNSHIP: {
    Medium: {
      weightText: '9 x 14 x 1 in',
      dimensionText: 'Up to 1kg',
    },
    Large: {
      weightText: '11 x 17 x 1 in',
      dimensionText: 'Up to 3kg',
    },
  },
};

export const COURIER_PRODUCT_SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  EXTRA_LARGE: 'EXTRA_LARGE',
};

export const STANDARD_PRODUCT_SIZES = {
  Small: 'S',
  Medium: 'M',
  Large: 'L',
  Extra_Large: 'XL',
};

export const NINJAVAN_METAFIELDS_TIME = [
  '09:00-12:00',
  '09:00-18:00',
  '09:00-22:00',
  '12:00-15:00',
  '15:00-18:00',
  '18:00-22:00',
];

export const ORDER_STATUS_SCHEDULED = {
  STARTED_PICKUP: 'For Pickup',
  DONE_PICKUP: 'Pickup Done',
  STARTED_DELIVERY: 'Rider is on the way to the delivery location',
  DONE: 'Order has been completed',
  CANCELLED: 'Order has been cancelled',
  ACCEPTED: 'For Pickup',
  ASSIGNED: 'For Pickup',
  FAILED_PICKUP: 'Failed Pickup',
  FAILED_DELIVERY: 'Failed Delivery',
  ARRIVED_AT_HUB: 'Arrived At Hub',
  IN_TRANSIT_FOR_RETURN: 'Return to Sender',
};

export const ORDER_SCHEDULED_SUB_HEADER = {
  STARTED_PICKUP:
    'A rider has been assigned to pick up your parcel. Note: You cannot cancel this order now.',
  DONE_PICKUP: 'Pickup Done',
  STARTED_DELIVERY: 'Rider is on the way to the delivery location',
  DONE: 'Order has been completed',
  CANCELLED: 'Order has been cancelled',
  ACCEPTED: 'Please prepare your parcel.',
  ASSIGNED: 'Please prepare your parcel.',
  FAILED_PICKUP: 'Failed Pickup',
  FAILED_DELIVERY: 'Failed Delivery',
  ARRIVED_AT_HUB: 'Arrived At Hub',
  IN_TRANSIT_FOR_RETURN: 'Return to Sender',
};
