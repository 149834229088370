import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';
import SilverMapStyle from '../../../../shared/utils/enums/SilverMapStyle.json';
import { ICON } from '../../../../shared/utils/enums/AppIcons';

const Maps = ({ coordinates, onSetPinLocation, onMarkerDragEnd }) => {
  useEffect(() => {
    return () => {
    };
  }, []);

  const position = {
    lat: coordinates?.latitude || coordinates?.lat,
    lng: coordinates?.longitude || coordinates?.lng,
  };
  const [center, setCenter] = useState(position);
  const refMap = useRef(null);

  return (
    <>
      <GoogleMap
        ref={refMap}
        defaultZoom={16}
        defaultCenter={center}
        center={center}
        defaultOptions={{
          styles: SilverMapStyle,
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: false,
          fullscreenControl: false,
        }}
        className="google-map-container"
      >
        <Marker
          draggable={true}
          onDragEnd={onMarkerDragEnd}
          position={center}
          icon={{
            url: ICON.MARKER,
            scaledSize: new window.google.maps.Size(40, 40),
          }}
        />
      </GoogleMap>
    </>
  );
};

export default withScriptjs(withGoogleMap(Maps));
