import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux/es/hooks/useSelector';

import { MODULE_PATH } from '../../../../../shared/constants/Module';

import { FOOTER_ICONS } from '../../../../../shared/utils/enums/AppIcons'


const Footer = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.usersReducer.currentUser);

  const handleNavigateAllOrder = () => {
    // if (user.recordCreated) {
      navigate(MODULE_PATH.ORDERS.VIEW_ALL_ORDERS, { state: { path: MODULE_PATH.HOME } });
    // }
  };

  const gotoCashBack = () => navigate(MODULE_PATH.MY_ACCOUNTS.CASH_BACK);
  const goToHelp = () => navigate(MODULE_PATH.EXTRAS.HELP_CENTER);
  const goToMyAccounts = () => navigate(MODULE_PATH.MY_ACCOUNTS.MAIN);

  return (
    <div className="footer">
      <div className="menu-item" onClick={() => handleNavigateAllOrder()}>
        <img src={FOOTER_ICONS.FTR_ALL_ORDERS} alt="All Orders" />
        <span className='mlLbl-fine'>All Orders</span>
      </div>
      <div className="menu-item">
        <img src={FOOTER_ICONS.FTR_CASHBACK} onClick={() => gotoCashBack()} alt="My Cashback" />
        <span className='mlLbl-fine'>My Cashback</span>
      </div>
      <div className="menu-item">
        <img src={FOOTER_ICONS.FTR_HELP} onClick={() => goToHelp()} alt="Help Center" />
        <span className='mlLbl-fine'>Help Center</span>
      </div>
      <div className="menu-item">
        <img src={FOOTER_ICONS.FTR_ACCOUNT} onClick={ () => goToMyAccounts() } alt="Account" />
        <span className='mlLbl-fine'>Account</span>
      </div>
    </div>
  );
};

export default Footer;
