import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import { Button, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  ORDER_STATUS,
  ORDER_STATUS_IMAGE,
  ORDER_SUB_HEADER,
} from '../../../../../shared/utils/enums/OrderStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment-timezone';
import {
  capitalizeFirstChar,
  copyToClipBoard,
  formatText,
} from '../../../../../shared/utils/helpers/purefunctions';
import PickupDropoff from './elements/PickupDropoff';
import PaymentDetails from './elements/PaymentDetails';
import {
  REFUND_STATUS,
  DEFAULT_REASON,
  DELIVERY_TYPE_VIEW,
} from '../../../../../shared/utils/enums/AppConstants';
import { isEmpty } from '../../../../../shared/utils/helpers/purefunctions';
import PaymentDAO from '../../../../../shared/utils/dao/PaymentDAO';
import {
  fetchCancelledOrders,
  fetchPendingOrders,
  fetchDoneOrders,
  setTargetOrder,
  setFormTab,
  setPrevRoute,
} from '../../../../../shared/redux/home/actions';
import { bindActionCreators } from 'redux';
import OrdersDAO from '../../../../../shared/utils/dao/Orders';
import { Fragment } from 'react';
import TrackOrderButton from '../../../../../shared/components/TrackOrderButton';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import { COURIER_IMAGES } from '../../../../../shared/utils/enums/CourierImages';
import { BG } from '../../../../../shared/utils/enums/AppIcons';
import {
  ORDER_SCHEDULED_SUB_HEADER,
  ORDER_STATUS_SCHEDULED,
  STANDARD_COURIERS,
} from '../../../../../shared/utils/enums/StandardDelivery';
import TrackOrderStandard from '../../../../../shared/components/TrackOrder TrackOrderStandard';
import StandardDeliveryDays from '../../../../../shared/components/StandardDeliveryDays';
import { FOOD_PANDA, PANDAGO } from '../../../../../shared/utils/enums/Providers';

import SMS from '../../../../../shared/assets/svg/sms.svg';
import CALL from '../../../../../shared/assets/svg/call.svg';
import { ICON } from '../../../../../shared/utils/enums/AppIcons';
import StickyHeader from '../../../../../shared/components/StickyHeader';
import AssignRiderLoader from '../../../../../shared/elements/Loader/AssignRiderLoader';

const OrderDetails = ({ orderDetails, setTargetOrder, user, ongoingTarget }) => {
  const navigate = useNavigate();
  const location = useLocation();
  let Trail = useRef(null);
  const [trail, setTrail] = useState([]);
  const [isDisabled, setIsDisabled] = useState(null);
  const [countdown, setCountdown] = useState(3);
  const {
    postId,
    gcash,
    lastUpdateRemarks,
    meta,
    metaFields,
    subStatus,
    status,
    refNo,
    courierId,
  } = orderDetails;

  const dispatch = useDispatch();
  const deliveryDetails = meta ? JSON.parse(meta) : {};
  const refundStatus = location?.state?.refundStatus;
  const refundAttempt = gcash?.refund?.attempt;
  const refundSuccess = gcash?.refund?.success;
  const isDeliveryInProgress = ['STARTED_DELIVERY', 'STARTED_PICKUP', 'DONE_PICKUP'].includes(
    subStatus
  );
  const isStatusDone = ['DONE'].includes(status);
  const isProcessComplete = isDeliveryInProgress || isStatusDone;

  /* We used partnerId instead of courierId as courierId doesn't always have a value. */
  const isMrSpeedyVehicle =
    String(metaFields?.mrSpeedyVehicle || metaFields?.serviceType || metaFields?.vehicle || '') ===
    '8';
  const grabVehicle =
    !metaFields?.grabVehicleType || metaFields?.grabVehicleType.toLowerCase() === 'bike'
      ? 'Motorcycle'
      : metaFields?.grabVehicleType;
  const serviceTypes = metaFields?.serviceType || metaFields?.vehicle || grabVehicle;
  const vehicleType = metaFields ? formatText(isMrSpeedyVehicle ? 'Motorcycle' : serviceTypes) : {};

  const isStandardDelivery = STANDARD_COURIERS.includes(courierId);
  const trackOrderLabel = isStandardDelivery ? 'standard' : 'ondemand';

  const { formTab } = useSelector(state => state.homeReducer);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    const isCurrentTarget = ongoingTarget[orderDetails?.refNo] || {};
    if (isEmpty(isCurrentTarget)) {
      setIsDisabled(false);
    } else {
      const fiveMinutesInMilliseconds = 3 * 1000;
      const endTimer = Number(isCurrentTarget.timeStamp + fiveMinutesInMilliseconds);
      const currentTime = moment().valueOf();
      const isLapse = endTimer > currentTime;
      setIsDisabled(isLapse);
      if (isLapse) {
        const remainingTime = Number(endTimer - currentTime);
        const ceilRemainingTime = Math.ceil(remainingTime / 1000);
        setCountdown(ceilRemainingTime); // Reset the countdown
        setTimeout(() => {
          setIsDisabled(false); // Re-enable the button after the countdown
        }, 3000);
      }
    }
  }, []);

  const getTrail = useCallback(async () => {
    Trail.current = new OrdersDAO();
    const trailData = await Trail.current.getOrderTrailOnce(postId);
    setTrail(trailData);
    const unsubscribe = Trail.current.subscribeToOrderTrailChanges(postId, updatedTrailData => {
      setTrail(updatedTrailData);
    });
    return () => unsubscribe();
  }, [postId]);

  useEffect(() => {
    getTrail();
  }, [getTrail]);

  useEffect(() => {
    if (isDisabled) {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000); // Update countdown every second
      return () => {
        clearInterval(timer);
      };
    }
  }, [isDisabled]);

  const RiderDetails = ({ trail }) => {
    let riderDetails;
    const handleDialClick = contactNumber => {
      window.open(`tel:${contactNumber}`);
    };

    const handleSMSClick = contactNumber => {
      const url = `sms:${contactNumber}`;
      window.location.href = url;
      // window.open(`sms:+${contactNumber}`);
    };

    trail &&
      trail.forEach(obj => {
        const isDriverAssigned = /DRIVER_ASSIGNED|Driver Name/.test(obj.remarks);
        if (isDriverAssigned) {
          const driverDetails = obj.remarks.split('\n');
          const riderName = driverDetails[1].split(':')[1] || '';
          const riderContact = driverDetails[2].split(':')[1] || '';
          const riderPlateNumber = driverDetails[3] ? driverDetails[3].split(':')[1] : '';

          riderDetails = (
            <div className="dial-rider">
              <span>Assigned Rider</span>
              <div className="rider-info">
                <div className="rider-details">
                  <div className="name">{riderName}</div>
                  <div className="plate-number">
                    <span>{riderPlateNumber}</span> ● {capitalizeFirstChar(vehicleType)}
                  </div>
                </div>
                <span className="copy__icon" onClick={() => handleSMSClick(riderContact)}>
                  <img src={SMS} />
                </span>
                <span className="copy__icon" onClick={() => handleDialClick(riderContact)}>
                  <img src={CALL} />
                </span>
              </div>
              <div className="courier">
                <img src={COURIER_IMAGES[courierId]} alt="courier logo" />
                <span>{courierId === FOOD_PANDA ? PANDAGO : capitalizeFirstChar(courierId)}</span>
              </div>
            </div>
          );
        }
      });
    return riderDetails;
  };
  const handleNavigateBookAgain = () => {
    dispatch(setPrevRoute('ViewAll'));
    isStandardDelivery
      ? dispatch(setFormTab(DELIVERY_TYPE_VIEW.standard))
      : dispatch(setFormTab(DELIVERY_TYPE_VIEW.onDemand));
    navigate(MODULE_PATH.HOME);
    return;
  };
  /* Refund actions */
  const handleReturnHome = useCallback(() => {
    Modal.confirm({
      className: 'cancel-order',
      title: 'Cancel Order',
      okText: 'No',
      cancelText: 'Yes',
      content: 'Are you sure you want to cancel your order?',
      onOk: () => {},
      onCancel: () => {
        navigate(MODULE_PATH.ORDERS.CANCEL_ORDER);
      },
    });
  }, [navigate]);

  const handleRefund = useCallback(async () => {
    const PaymentDao = new PaymentDAO();
    const { paymentAmount, paymentId, paymentRequestId, cancelledReason } = orderDetails.gcash;
    const { Success } = REFUND_STATUS;

    let refund;
    let refundResultStatus;

    navigate(MODULE_PATH.EXTRAS.LOADER);
    refund = await PaymentDao.refund({
      postId: orderDetails?.postId || null,
      mdbId: orderDetails.mdbId,
      orderNo: refNo,
      paymentId,
      paymentRequestId,
      refundAmount: paymentAmount,
      contactNumber: user.gcashNumber,
      name: `${user.firstName} ${user.lastName}`,
      userId: user.id,
      refundReason: cancelledReason || DEFAULT_REASON,
    });

    refundResultStatus = refund.result?.resultStatus;

    if (refundResultStatus === Success) {
      return navigate(MODULE_PATH.ORDERS.ORDER_CANCELLED);
    }

    return navigate(MODULE_PATH.EXTRAS.ERROR, {
      state: { refundStatus: refundResultStatus, attempt: gcash?.refund?.attempt },
    });
  }, [navigate, orderDetails.gcash, orderDetails?.postId, user.id]);

  const RefundActions = useCallback(() => {
    if (isDisabled === null) {
      return (
        <Button className="request-refund disable" loading>
          Loading
        </Button>
      );
    }
    if (status === 'CANCELLED' && gcash?.refund?.success) {
      return (
        <Button className="cancel-btn" onClick={handleNavigateBookAgain}>
          Rebook Order
        </Button>
      );
    }

    if (
      !gcash?.refund?.attempt ||
      (status === 'CANCELLED' &&
        (!gcash?.refund?.success || gcash?.success === 'SUCCESS') &&
        gcash?.refund?.attempt < 3)
    ) {
      return (
        <Button
          className={`${isDisabled ? 'request-refund disable' : 'cancel-btn'}`}
          onClick={() => handleRefund()}
        >
          {isDisabled ? `Please try again (${countdown})` : 'Request Refund'}
        </Button>
      );
    }
    if (location?.state?.refundStatus === 'F') {
      <Button className="request-refund disable">Please Contact Support.</Button>;
    }
    return <Button className="request-refund disable">Please Contact Support.</Button>;
  }, [handleRefund, gcash, status, navigate, countdown, isDisabled]);

  /* Rider status and subStatus */
  const RiderSubStatus = ({ subStatus }) => {
    const commonElements = (
      <div className="rider-details">
        <h3 className="rider-status">
          {isStandardDelivery ? ORDER_STATUS_SCHEDULED[subStatus] : ORDER_STATUS[subStatus]}
        </h3>
        <span>
          {isStandardDelivery ? ORDER_SCHEDULED_SUB_HEADER[subStatus] : ORDER_SUB_HEADER[subStatus]}
        </span>
      </div>
    );

    if (subStatus === 'STARTED_PICKUP') {
      return (
        <>
          {commonElements}
          <img src={ORDER_STATUS_IMAGE['RIDER_PICKUP']} alt="Order Status Icon" />
        </>
      );
    }

    return (
      <>
        {commonElements}
        <img src={ORDER_STATUS_IMAGE['ORDER_ARRIVED']} alt="Order Status Icon" />
      </>
    );
  };

  const RiderStatus = ({ status, subStatus }) => {
    let title, message, imageKey;

    switch (status) {
      case 'ACCEPTED':
      case 'ASSIGNING_RIDER':
        title = 'Assigning a rider';
        message = 'Please wait for the booking to be accepted.';
        imageKey = 'ASSIGNING_RIDER';
        break;
      case 'FAILED_PICKUP':
        title = 'Failed Pickup';
        message = 'Failed Pickup';
        imageKey = 'ASSIGNING_RIDER';
        break;
      case 'FAILED_DElIVERY':
        title = 'Failed Delivery';
        message = 'Failed Delivery';
        imageKey = 'ASSIGNING_RIDER';
        break;
      case 'STARTED':
        return <RiderSubStatus subStatus={subStatus} />;
      case 'DONE':
        title = 'Order has been completed';
        message = 'Rider has successfully delivered the item.';
        imageKey = 'ORDER_COMPLETED';
        break;
      case 'CANCELLED':
        title = 'Order has been cancelled';
        message = gcash?.hasOwnProperty('cancelledReason')
          ? `${gcash.cancelledReason}.`
          : lastUpdateRemarks;
        imageKey = 'ORDER_CANCELLED';
        break;
      default:
        title = 'Assigning a rider';
        message = 'Please wait for the booking to be accepted';
        imageKey = 'ASSIGNING_RIDER';
    }

    return (
      <>
        <div className="rider-details">
          <h3 className="rider-status">
            {isStandardDelivery ? ORDER_STATUS_SCHEDULED[status] : title}
          </h3>
          <span>
            {isStandardDelivery
              ? ORDER_SCHEDULED_SUB_HEADER[status] || ORDER_SCHEDULED_SUB_HEADER[subStatus]
              : message}
          </span>
          {status === 'ACCEPTED' && <AssignRiderLoader />}
        </div>
        <img src={ORDER_STATUS_IMAGE[imageKey]} alt="Order Status Icon" />
      </>
    );
  };

  const CancelOrderBtn = useCallback(() => {
    const { status, subStatus, meta, gcash } = orderDetails;
    const deliveryDetails = meta ? JSON.parse(meta) : {};
    const { trackingNumber } = deliveryDetails;
    const isPickupNowQueued = ['QUEUED'].includes(status) && gcash?.pickupNow;
    const isStatusForDisable = ['STARTED_DELIVERY', 'STARTED_PICKUP'].includes(subStatus);

    if (isStatusForDisable || isPickupNowQueued || (!isStandardDelivery && !trackingNumber)) {
      return <Button className="disable-cancel-btn">Cancel Order</Button>;
    }

    if (!gcash?.pickupNow) {
      return (
        <Button className="cancel-btn" onClick={handleReturnHome}>
          Cancel Order
        </Button>
      );
    }

    if (status === 'DONE') {
      return (
        <Button className="cancel-btn" onClick={handleNavigateBookAgain}>
          Book Again
        </Button>
      );
    }

    return (
      <Button className="cancel-btn" onClick={handleReturnHome}>
        Cancel Order
      </Button>
    );
  }, [handleReturnHome, orderDetails, gcash?.pickupNow]);

  const navigateToPrevPage = () => {
    navigate(location?.state?.link || '/');
  };

  const styles = {
    cover: {
      backgroundImage: `url(${BG.ORDER_DETAILS})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
  };
  if (isEmpty(orderDetails)) {
    return navigate(MODULE_PATH.HOME);
  }

  const prevNavigate = {
    label: 'Order Details',
    imgPath: '',
    path: location?.state?.link || '/',
  };
  return (
    <div className="order-details-container" style={styles.cover}>
      <StickyHeader title="Order Details" onPrevNavigate={prevNavigate} />
      <div className="order-status">
        <strong className="hdln-block">
          Order No. {orderDetails.refNo}{' '}
          <span>
            <span onClick={() => copyToClipBoard(orderDetails.refNo)} className="copy__icon">
              <FontAwesomeIcon icon={solid('copy')} />
            </span>
          </span>
        </strong>
        <div className="order-status-group">
          <p className="del-type">Order Status:</p>
          <RiderStatus status={orderDetails.status} subStatus={orderDetails.subStatus} />
        </div>
        {!isStandardDelivery && <TrackOrderButton label={trackOrderLabel} order={orderDetails} />}
      </div>
      {isStandardDelivery && isProcessComplete && <TrackOrderStandard trail={trail} />}
      {isStandardDelivery && <StandardDeliveryDays />}
      {!isStandardDelivery && (
        <div className="order-schedule">
          <span>Pickup schedule</span>
          <h1>{moment(orderDetails.completionDateTime).format('ddd, MMM D YYYY, h:mm A')}</h1>
        </div>
      )}
      {isProcessComplete ? <RiderDetails trail={trail} /> : ''}
      <PickupDropoff pickUpdetails={orderDetails} deliveryDetails={deliveryDetails} />
      <div className="item-details">
        <h1 className="test">Item Details</h1>
        <span>{gcash?.itemType || ''}</span>
        <span>{deliveryDetails?.details || ''}</span>
      </div>
      <PaymentDetails
        payment={gcash}
        finalShippingFee={orderDetails.finalShippingFee}
        refundStatus={refundStatus}
        refundAttempt={refundAttempt}
        refundSuccess={refundSuccess}
        status={status}
      />
      <div className="order-details-btn">
        <Button className="need-help-btn" onClick={() => navigate(MODULE_PATH.EXTRAS.HELP)}>
          <FontAwesomeIcon icon={solid('envelope-open-text')} />
          Need Help?
        </Button>
        {orderDetails?.status === 'CANCELLED' ? <RefundActions /> : <CancelOrderBtn />}
      </div>
    </div>
  );
};

const matchDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchCancelledOrders,
      fetchPendingOrders,
      fetchDoneOrders,
      setTargetOrder,
    },
    dispatch
  );
};

const mapStateToProps = state => ({
  orderDetails: state.homeReducer.targetOrder,
  user: state.usersReducer.currentUser,
  ongoingTarget: state.transactionReducer.ongoingTarget,
});
export default connect(mapStateToProps, matchDispatchToProps)(memo(OrderDetails));
